import { SiteEnvironment } from 'app/types'
import { Environments } from 'utils/env'

/**
 * wawa.int.corestream.com (int)
 * wawa.corestream.com (prod)
 * wawa.test.corestream.com (test)
 * wawa.dev.corestream.com (dev)
 *
 * special case is when we have a preview environment where we will let the user select the environemnt + tenant
 * example: <hash>.preview.corestream.com (preview)
 *
 * @param hostStr
 */
export function parseEnvAndDomainFromHost(hostStr: string): SiteEnvironment {
	const parts = hostStr.split('.')
	// admin.corestream.com
	// admin.dev.corestream.com
	// admin.int.corestream.com
	// asdf.admin.preview.corestream.com

	const env = parts.at(-3) // dev, int, preview, test, prod (admin - won't match)

	const envMapping = {
		dev: Environments.DEV,
		int: Environments.INT,
		preview: 'preview',
		test: Environments.UAT,
	}

	const prodList = ['admin']

	return {
		//@ts-expect-error asdf
		env: !prodList.includes(env ?? '') ? envMapping[env] : Environments.PROD,
	}
}
