import { PlanCoverage } from 'types/tenants/rates'
import { RatesFormType } from './types'

export class RatesEdit implements RatesFormType {
	rateInfoId: string
	enrollmentEffectiveDate: string | Date
	notes: string
	planCoverages: PlanCoverage[]
	reference: string | File

	constructor(data) {
		this.rateInfoId = data.rateInfoId
		this.notes = data.notes
		this.planCoverages = data.planCoverages
		this.reference = data.reference
		this.enrollmentEffectiveDate = data.enrollmentEffectiveDate
	}
}
