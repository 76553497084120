import {
	Box,
	Checkbox,
	Chip,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemText,
	MenuItem,
	MenuProps,
	Select,
	Theme,
	Tooltip,
	createStyles,
	makeStyles,
} from '@material-ui/core'
import React, { ReactNode, useMemo } from 'react'

import styled from 'styled-components'

const MultipleSelectBox = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;
`

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1),
			maxWidth: 350,
			minWidth: '30%',
		},
		fullWidth: {
			width: '100%',
		},
	}),
)

interface FormMultiSelectProps {
	autoWidth?: boolean
	className?: string
	renderType?: 'comma separated' | 'chips'
	error?: string[]
	fullWidth?: boolean
	handleChange: (...args: any[]) => void
	id?: string
	items: any[]
	label?: string
	menuProps?: Partial<MenuProps> | undefined
	name: string
	onBlur?: any
	selected: any[]
	variant?: 'outlined' | 'filled' | 'standard'
	hasExclusiveOptions?: boolean
}

type FormMultiSelectType = React.FC<FormMultiSelectProps>
const FormMultiSelect: FormMultiSelectType = ({
	autoWidth = false,
	className,
	renderType,
	error,
	fullWidth,
	handleChange,
	id,
	items = [],
	label,
	menuProps,
	name,
	onBlur,
	selected = [],
	variant = 'filled',
	hasExclusiveOptions = false,
}: FormMultiSelectProps) => {
	const classes = useStyles()
	const getSelectedNames = (selected): string => getSelectedArray(selected, typeof items[0] === 'object').join(', ')
	const getName = (item, isObject: boolean): string => (isObject ? item.name : item)
	const getId = (item, isObject: boolean): string => (isObject ? item.id : item)
	const isChecked = (item: any, isObject: boolean): boolean => {
		return isObject ? selected.some((s: any) => s === item.id) : selected.indexOf(item) > -1
	}
	const getSelectedArray = (selected, isObject: boolean): string[] =>
		isObject ? selected.map((s) => items.find((item) => s === item.id)?.name).filter((x) => x) : (selected as string[])
	const handleExclusiveChange = (e: any) => {
		let selectedValues: number[] = e.target.value
		const exclusiveOptionIds = items.filter((x) => x.isExclusive).map((x) => x.id)

		// Handle an exclusive option being selected
		const newValue = selectedValues.find((x) => !selected.includes(x))
		if (newValue && exclusiveOptionIds.includes(newValue)) {
			selectedValues = [newValue]
		}

		// Handle an exclusive option previously existing
		if (selectedValues.length > 1) {
			selectedValues = selectedValues.filter((value) => !exclusiveOptionIds.includes(value))
		}

		handleChange({ target: { name, value: selectedValues } })
	}

	return (
		<FormControl
			className={fullWidth ? classes.fullWidth : classes.formControl}
			error={Boolean(error != null && error.length > 0)}
			variant='filled'
		>
			<InputLabel id={label}>{label}</InputLabel>
			<Select
				className={className}
				labelId={label}
				id={id}
				value={selected}
				inputProps={{ onBlur }}
				onChange={hasExclusiveOptions ? handleExclusiveChange : handleChange}
				name={name}
				renderValue={(selected): React.ReactNode => {
					if (renderType === 'chips') {
						return <MultipleSelectChips items={items} selected={selected as any} />
					}

					return (
						<Tooltip title={getSelectedNames(selected)}>
							<span>{getSelectedNames(selected)}</span>
						</Tooltip>
					)
				}}
				MenuProps={menuProps || { PaperProps: { style: { height: 350 } } }}
				variant={variant}
				autoWidth={autoWidth}
				multiple
			>
				{items.map((item, index: number) => {
					const isObject = typeof item === 'object'

					return (
						<MenuItem key={index} value={getId(item, isObject)}>
							<Checkbox checked={isChecked(item, isObject)} />
							<Tooltip title={item.description ?? ''}>
								<ListItemText primary={getName(item, isObject)} />
							</Tooltip>
						</MenuItem>
					)
				})}
			</Select>
			<FormHelperText error>{error?.join(', ')}</FormHelperText>
		</FormControl>
	)
}

interface MultipleSelectOptions<T extends number | string> {
	id: T
	name: ReactNode
}

function MultipleSelectChips<T extends number | string>(props: { items: MultipleSelectOptions<T>[]; selected: T[] }) {
	const optionsDict: Record<T, ReactNode> = useMemo(() => {
		return Object.assign({}, ...props.items.map((x) => ({ [x.id]: x.name })))
	}, [props.items])

	return (
		<MultipleSelectBox>
			{props.selected.map((valueId) => {
				const optionName = optionsDict[valueId] || 'UNKNOWN VALUE'

				return <Chip color='primary' key={valueId} label={optionName} />
			})}
		</MultipleSelectBox>
	)
}

export default FormMultiSelect
