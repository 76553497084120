import { Reducer } from 'redux'
import { SNACKBAR_CLEAR, SNACKBAR_ERROR, SNACKBAR_SUCCESS } from './types'

export type SnackbarState = {
	snackbarOpen: boolean
	snackbarMessage: string
	snackbarStatus?: 'error' | 'success' | 'info' | 'warning'
}
const initialState: SnackbarState = {
	snackbarMessage: '',
	snackbarOpen: false,
	snackbarStatus: 'success',
}

export const uiReducer: Reducer<SnackbarState> = (state = initialState, action) => {
	switch (action.type) {
		case SNACKBAR_SUCCESS:
			return {
				...state,
				snackbarMessage: action.payload,
				snackbarOpen: true,
				snackbarStatus: 'success',
			}
		case SNACKBAR_ERROR:
			return {
				...state,
				snackbarMessage: action.payload,
				snackbarOpen: true,
				snackbarStatus: 'error',
			}
		case SNACKBAR_CLEAR:
			return {
				...state,
				snackbarOpen: false,
			}
		default:
			return state
	}
}
