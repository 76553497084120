import { CsRoute } from 'routes/types'

export function prepareRoutes(breadcrumbRoutes: CsRoute[], { stripLeading = '/' }) {
	return breadcrumbRoutes
		.filter((i) => i.flag || i.flag === undefined || i.flag === null)
		.map((route) => ({
			...route,
			path: stripLeading
				? route?.path?.startsWith(stripLeading)
					? route?.path.slice(stripLeading.length)
					: route.path
				: route.path,
		}))
}
