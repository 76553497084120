import { Paper } from '@material-ui/core'
import React from 'react'

import styles from './maintenance.module.scss'

const Maintenance: React.FC = () => {
	return (
		<div className={styles.maintenanceWrapper}>
			<Paper className={styles.maintenanceContent}>
				<h1>Site is currently down for scheduled maintenance.</h1>
				<h3>We expect to be back in a couple hours. Thanks for your patience.</h3>
			</Paper>
		</div>
	)
}

export default Maintenance
