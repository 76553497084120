import { combineReducers } from 'redux'
import { uiReducer } from './snackbar/reducer'
import { user } from './user/reducer'

export const rootReducer = combineReducers({
	uiReducer,
	user,
})

export type RootState = ReturnType<typeof rootReducer>
