import './layout.scss'

import { Container, CssBaseline } from '@material-ui/core'
import { AppBar, Unstable_Grid2 as Grid, Hidden, Toolbar, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Menu } from '@mui/icons-material'
import { IconButton } from 'components/buttons'
import SideNav from 'components/side-nav'
import UserMenu from 'components/user-menu'
import { AppBreadcrumbs } from 'routes/breadcrumbs'
import logoUrl from '../assets/corestreamlogo.png?url'

const Layout = () => {
	const [open, setOpen] = useState(false)
	const handleDrawerToggle = (): void => setOpen((prevState): boolean => !prevState)
	const navigate = useNavigate()

	const logo: React.ReactNode = (
		<div className='logo-tooltip-container'>
			<Tooltip title='Return Home'>
				<img alt='logo' className='logo' height='40px' onClick={(): void => navigate('/')} src={logoUrl} />
			</Tooltip>
		</div>
	)

	return (
		<>
			<AppBar
				position='sticky'
				sx={(theme) => ({
					backgroundColor: '#857770',
					color: '#eee',
					height: '4rem',
					zIndex: theme.zIndex.drawer + 1,
				})}
			>
				<Toolbar sx={{ width: '100%' }}>
					<Grid spacing={0} alignItems='center' container width={'100%'}>
						<Hidden mdUp>
							<Grid xs={1}>
								<IconButton ariaLabel='open drawer' color='inherit' edge='start' onClick={handleDrawerToggle}>
									<Menu />
								</IconButton>
							</Grid>
						</Hidden>

						<Grid xs={10} container alignItems='center'>
							<Hidden smDown>{logo}</Hidden>
							<AppBreadcrumbs />
						</Grid>

						<Grid xs={1} md={2} container justifyContent='flex-end'>
							<UserMenu />
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<div
				style={{
					backgroundColor: '#eee',
					display: 'flex',
					height: 'calc(100vh - 4rem)',
					width: '100%',
				}}
			>
				<CssBaseline />

				<SideNav open={open} handleDrawerToggle={handleDrawerToggle} />
				<Container maxWidth={false} style={{ paddingTop: '20px', paddingBottom: '20px', overflow: 'auto' }}>
					<Grid
						spacing={2}
						justifyContent='flex-start'
						alignItems='flex-start'
						container
						style={{ flexGrow: 1, minHeight: '86vh' }}
					>
						<Outlet />
					</Grid>
				</Container>
			</div>
		</>
	)
}

export default Layout
