import React from 'react'

import Modal from 'components/modal'
import { Snackbar } from 'components/snackbar'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { Button } from 'components/buttons'
import { MutationStatus } from 'react-query'

export function renderConfirmModal(
	showModal: boolean,
	handleClose,
	handleSubmit,
	message: string,
	title: string,
	isLoading?: boolean,
): React.ReactNode {
	return (
		<Modal
			actions={
				<>
					<Button disabled={isLoading} onClick={handleClose} variant='outlined'>
						No
					</Button>
					<Button disabled={isLoading} isLoading={isLoading} onClick={handleSubmit}>
						Yes
					</Button>
				</>
			}
			id={`Confirm ${title}`}
			handleClose={handleClose}
			open={showModal}
			title={title}
		>
			{message}
		</Modal>
	)
}

export function renderDeleteConfirmModal(showModal: boolean, handleClose, title: string): React.ReactNode {
	return (
		<Modal id='Confirm Cancel' handleClose={handleClose} open={showModal} title={title}>
			Changes will not be saved. Are you sure you want to cancel?
		</Modal>
	)
}

export function renderSnackbar(
	status: StateMachineStatus | MutationStatus,
	successMessage: string,
	errorMessage: string,
	position?: { horizontal: 'left' | 'center' | 'right'; vertical: 'bottom' | 'top' },
): React.ReactNode {
	const open = status === 'success' || status === 'error'
	const updatedStatus = status === 'error' ? 'error' : 'success'
	let message = ''
	if (status === 'success') {
		message = successMessage
	} else if (status === 'error') {
		message = errorMessage
	}

	return <Snackbar position={position} message={message} open={open} status={updatedStatus} />
}
