import { Button as MuiButton } from '@material-ui/core'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { LinkButtonProps } from './types'

/**
 * Based on Material UI button
 * @see https://material-ui.com/components/buttons/#ContainedButtons.tsx
 */
const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
	return (
		<MuiButton
			aria-label={props.ariaLabel}
			className={props.className ?? ''}
			color={props.color}
			component={RouterLink}
			disabled={props.disabled}
			id={props.id ?? ''}
			style={props.style}
			to={props.to}
			variant={props.variant}
			target={props.openInNewTab ? '_blank' : ''}
			rel={props.openInNewTab ? 'noopener' : ''}
			endIcon={props.endIcon}
		>
			{props.children}
		</MuiButton>
	)
}
LinkButton.defaultProps = {
	color: 'primary',
	openInNewTab: false,
	size: 'medium',
	to: '',
	variant: 'contained',
}
export default LinkButton
