import React, { useEffect, useState } from 'react'
import { Snackbar as MuiSnackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

const Alert: React.FC<AlertProps> = (props: AlertProps) => <MuiAlert elevation={6} variant='filled' {...props} />

interface SnackBarProps {
	message: string
	open: boolean
	onClose?: () => void
	position?: { horizontal: 'left' | 'center' | 'right'; vertical: 'bottom' | 'top' }
	shouldAutoHide?: boolean
	status?: 'error' | 'success' | 'info' | 'warning'
}

const Snackbar: React.FC<SnackBarProps> = (props: SnackBarProps) => {
	const autoHide = 5000 // 5s in ms
	const [localOpen, setLocalOpen] = useState(props.open ?? false)
	const handleClose = (e: React.SyntheticEvent, reason?: string): void | null => {
		if (e?.type === 'click') {
			setLocalOpen(false)
		}
		if (reason === 'clickaway' || reason === 'timeout') {
			setLocalOpen(false)
			if (props.onClose) {
				props.onClose()
			}
		}
	}

	useEffect(() => {
		setLocalOpen(props.open)
	}, [props.open])

	return (
		<MuiSnackbar
			anchorOrigin={{
				horizontal: props.position ? props.position.horizontal : 'center',
				vertical: props.position ? props.position.vertical : 'bottom',
			}}
			autoHideDuration={autoHide}
			open={localOpen}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={props.status}>
				{props.message}
			</Alert>
		</MuiSnackbar>
	)
}

export default Snackbar
