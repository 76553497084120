import { IconButton as MuiButton } from '@material-ui/core'
import React from 'react'
import { StandardButtonProps } from './types'

/**
 *
 * @param props - Only prop required is 'children' for the icon
 * @see https://material-ui.com/components/buttons/#IconButtons.tsx
 */
const IconButton: React.FC<StandardButtonProps> = (props: StandardButtonProps) => {
	return (
		<MuiButton
			aria-label={props.ariaLabel}
			className={props.className}
			color={props.color}
			disabled={props.disabled}
			edge={props.edge}
			id={props.id}
			onClick={props.onClick}
			style={props.style}
		>
			{props.children}
		</MuiButton>
	)
}

IconButton.defaultProps = {
	color: 'primary',
	size: 'small',
}

export default IconButton
