import { composeWithDevTools } from '@redux-devtools/extension'
import { Middleware, applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { RootState, rootReducer } from './rootReducer'

// createLogger to create custom logger

// Custom logger
const customLogger = createLogger({
	// to show the difference between what changed in state
	diff: true,

	// to log time
	duration: true,
	timestamp: true,

	// custom colors for each log
})

const middleware =
	import.meta.env.MODE === 'production'
		? applyMiddleware(thunk as ThunkMiddleware<RootState>)
		: applyMiddleware(customLogger as Middleware, thunk as ThunkMiddleware<RootState>)

// const store = createStore(
// 	rootReducer,
// 	middleware,
// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// 	// @ts-ignore
// 	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
// )

function configureStore(preloadedState) {
	const middlewares = middleware

	const enhancers = [middlewares]
	const composedEnhancers = composeWithDevTools(...enhancers)

	const store = createStore(rootReducer, preloadedState, composedEnhancers)

	return store
}

const store = configureStore({})

export default store
