import { Link } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { UIMatch, generatePath } from 'react-router-dom'
import { routePaths } from './route-paths'
import { CsRoute } from './types'
import { prepareRoutes } from './utils/prepareRoutes'

export const useOrgProviderRoutes = () => {
	// flags
	const { transformers8737 } = useFlags()

	const routes = prepareRoutes(
		[
			useProviderConfirmationConfiguration(),
			useProviderOutboundElectionConfigurationRoutes(),
			useProviderBillFileConfigurationRoutes(),
			{
				path: routePaths.organizations.edit.providers.edit.configuration.path,
				flag: transformers8737,
				lazy: () =>
					import('pages/organization-provider-configuration').then((module) => ({
						Component: module.ManageOrganizationProviderConfiguration,
					})),
			},
		],
		{
			stripLeading: `${routePaths.organizations.edit.providers.edit.path}/`,
		},
	)

	return routes
}

export const useProviderConfirmationConfiguration = (): CsRoute => {
	return {
		path: routePaths.organizations.edit.providers.edit.providerConfirmationConfiguration.path,
		handle: {
			crumb: (data: UIMatch<any, any>) => (
				<Link
					href={generatePath(routePaths.organizations.edit.providers.edit.providerConfirmationConfiguration.path, {
						id: data.params.id ?? '',
						providerId: data.params.providerId ?? '',
					})}
				>
					Provider Confirmation Configuration
				</Link>
			),
		},
		lazy: () =>
			import('pages/provider-confirmation-configuration').then((module) => ({
				Component: module.default,
			})),
	}
}

export const useProviderOutboundElectionConfigurationRoutes = (): CsRoute => {
	return {
		path: routePaths.organizations.edit.providers.edit.outboundElectionConfiguration.path,
		handle: {
			crumb: (data) => (
				<Link
					href={generatePath(routePaths.organizations.edit.providers.edit.outboundElectionConfiguration.path, {
						id: data.params.id,
						providerId: data.params.providerId,
					})}
				>
					Outbound Election Files
				</Link>
			),
		},
		children: prepareRoutes(
			[
				{
					index: true,
					lazy: () =>
						import('pages/outbound-election-config/outbound-election-config-listing').then((module) => ({
							Component: module.default,
						})),
				},
				{
					path: routePaths.organizations.edit.providers.edit.outboundElectionConfiguration.create.path,
					lazy: () =>
						import('pages/outbound-election-config/outbound-election-config-create').then((module) => ({
							Component: module.default,
						})),
				},
				{
					path: routePaths.organizations.edit.providers.edit.outboundElectionConfiguration.update.path,
					lazy: () =>
						import('pages/outbound-election-config/outbound-election-config-update').then((module) => ({
							Component: module.default,
						})),
				},
			],
			{
				stripLeading: `${routePaths.organizations.edit.providers.edit.outboundElectionConfiguration.path}/`,
			},
		),
	}
}

export const useProviderBillFileConfigurationRoutes = () => {
	return {
		path: `${routePaths.organizations.edit.providers.edit.billFileConfiguration.path}/*`,
		handle: {
			crumb: (data) => (
				<Link
					href={generatePath(routePaths.organizations.edit.providers.edit.billFileConfiguration.path, {
						id: data.params.id,
						providerId: data.params.providerId,
					})}
				>
					Incoming Billing Files
				</Link>
			),
		},
		children: prepareRoutes(
			[
				{
					index: true,
					lazy: () =>
						import('pages/bill-file-configuration/bill-file-configuration').then((module) => ({
							Component: module.default,
						})),
				},
				{
					path: routePaths.organizations.edit.providers.edit.billFileConfiguration.create.path,
					lazy: () =>
						import('pages/bill-file-configuration/bill-file-configuration-create').then((module) => ({
							Component: module.default,
						})),
				},
				{
					path: routePaths.organizations.edit.providers.edit.billFileConfiguration.update.path,
					lazy: () =>
						import('pages/bill-file-configuration/bill-file-configuration-update').then((module) => ({
							Component: module.default,
						})),
				},
			],
			{
				stripLeading: `${routePaths.organizations.edit.providers.edit.billFileConfiguration.path}/`,
			},
		),
	}
}
