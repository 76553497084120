import { Link } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate } from 'react-router-dom'
import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useCorestreamConfigurationRoutes = () => {
	const { transformers8737 } = useFlags()

	return prepareRoutes(
		[
			{
				lazy: () =>
					import('pages/corestream-configuartions').then((module) => ({
						Component: module.CorestreamConfigurationsLayout,
					})),
				flag: transformers8737,
				handle: {
					crumb: () => <Link href={routePaths.corestreamConfigurations.root}>Corestream Configurations</Link>,
				},
				path: routePaths.corestreamConfigurations.root,
				children: prepareRoutes(
					[
						{
							path: '*',
							element: <Navigate to='bank-accounts' replace />,
						},
						{
							lazy: () =>
								import('pages/corestream-bank-account').then((module) => ({ Component: module.CorestreamBankListing })),
							path: routePaths.corestreamConfigurations.bankAccounts.path,
						},
					],
					{
						stripLeading: `${routePaths.corestreamConfigurations.root}/`,
					},
				),
			},
		],
		{},
	)
}
