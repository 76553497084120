import React from 'react'
import { FormControl, FormControlLabel } from '@material-ui/core'
import Checkbox from 'components/checkbox'

interface FormCheckboxProps {
	checked: boolean
	color?: 'primary' | 'secondary'
	disabled?: boolean
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	id?: string
	label: string
	labelPlacement?: 'bottom' | 'end' | 'start' | 'top'
	name: string
}

const FormCheckbox: React.FC<FormCheckboxProps> = (props: FormCheckboxProps) => {
	const { color, disabled, handleChange, id, label, labelPlacement, name } = props

	return (
		<FormControl component='fieldset'>
			<FormControlLabel
				id={id}
				labelPlacement={labelPlacement}
				label={label}
				control={
					<Checkbox
						ariaLabel={name}
						checked={props.checked}
						color={color}
						disabled={disabled}
						name={name}
						onChange={handleChange}
					/>
				}
			/>
		</FormControl>
	)
}

FormCheckbox.defaultProps = {
	disabled: false,
	labelPlacement: 'top',
}

export default FormCheckbox
