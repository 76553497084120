import { Breadcrumbs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { cx } from 'class-variance-authority'
import { ReactNode } from 'react'
import { useMatches } from 'react-router-dom'
import styles from './app-breadcrumbs.module.css'

export const AppBreadcrumbs = () => {
	const matches = useMatches()

	const crumbs = matches
		// first get rid of any matches that don't have handle and crumb
		//@ts-expect-error - handle is not in the type definition
		.filter((match) => Boolean(match.handle?.crumb))
		// now map them into an array of elements, passing the loader
		// data to each one
		.map((match) => {
			return {
				match,
				//@ts-expect-error - handle is not in the type definition
				breadcrumb: match.handle?.crumb(match) as ReactNode,
			}
		})

	return (
		<Breadcrumbs separator={<NavigateNextIcon fontSize='small' style={{ color: 'white' }} />} aria-label='breadcrumb'>
			{crumbs.map((crumb, key) => (
				<h4 key={crumb.match.id} className={cx(styles['text'], key === crumbs.length - 1 && styles['last'])}>
					{crumb.breadcrumb}
				</h4>
			))}
		</Breadcrumbs>
	)
}
