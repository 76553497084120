import { Grid, Paper } from '@material-ui/core'
import { useGetBenefitPlanById } from 'api/benefit-elections/queries/use-get-benefit-plan-by-id'
import { FormDatePicker, FormInput, FormUploader } from 'components/form'
import Text from 'components/text'
import { UseFormReturnType } from 'hooks/use-form'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { convertBtoMb, formatDateString } from 'utils'
import RatesFormHeader from './rates-form-header'
import './rates-form.scss'
import RatesTable from './rates-table'
import { RatesFormType } from './types'

interface RatesFormProps {
	form: UseFormReturnType<RatesFormType>
	isEditRate?: boolean
}

const RatesForm = ({ form, isEditRate = false }: RatesFormProps) => {
	const { id, benefitPlanId } = useParams<{
		id: string
		benefitPlanId: string
	}>()
	const { data: benefitPlan } = useGetBenefitPlanById({ benefitPlanId: benefitPlanId! })
	const { handleSubmit, setFields, isSubmitting } = form

	const handleFileUpload = (file): void => {
		setFields({
			target: {
				name: 'reference',
				value: file,
			},
		})
	}

	const shortFileName = useMemo(() => {
		if (isEditRate && typeof form.values.reference === 'string') {
			const splitFileName = form.values.reference.split('/')
			const fileName = splitFileName[splitFileName.length - 1]

			return fileName
		}

		return ''
	}, [form.values.reference, isEditRate])

	return (
		<Paper elevation={3} className='paper-spacing'>
			<Grid container alignItems='center' spacing={3}>
				<RatesFormHeader
					isSubmitting={isSubmitting}
					handleSubmit={handleSubmit}
					benefitPlan={benefitPlan}
					orgId={id!}
				/>
				<Grid item container spacing={3} alignItems='center'>
					<Grid item xs={6}>
						{isEditRate ? (
							<>
								<Text variant='body2'>Enrollment Effective Date</Text>
								<p>{formatDateString(form.values.enrollmentEffectiveDate as string)}</p>
							</>
						) : (
							<FormDatePicker
								format='MM/DD/YYYY'
								error={Boolean(form.errors?.enrollmentEffectiveDate)}
								errorMessage={form.errors?.enrollmentEffectiveDate}
								label='Enrollment Effective Date'
								name='enrollmentEffectiveDate'
								onChange={setFields}
								value={form.values.enrollmentEffectiveDate}
								labelPlacement='start'
								formControlStyle={{ width: '100%' }}
								required
							/>
						)}
					</Grid>

					<Grid item xs={6}>
						<FormUploader
							acceptedTypes={['.pdf', '.docx', '.xlsx', '.doc', '.xls']}
							label='Reference'
							handleDrop={handleFileUpload}
							maxSize={convertBtoMb(10)}
							name='reference'
							useSmallTextLabel
						/>
						{form.values.reference && typeof form.values.reference === 'string' && (
							<Grid item>
								<a href={form.values.reference} target='_blank' rel='noreferrer'>
									{shortFileName}
								</a>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid container item xs={12}>
					<FormInput
						name='notes'
						value={form.values.notes}
						label='Notes'
						onChange={setFields}
						type='text'
						omitExtraRightSpacing
					/>
				</Grid>
				{benefitPlan?.shouldRequireSpouseInfoForPricing && (
					<Grid item xs={6}>
						<Text variant='body2'>
							Please Note: Family rates are not entered here since they are calculated based on EE + ES + EC. The EE
							rates vary based on Employee age, and ES rates vary based on Spouse age. The sum of EE + ES will be the
							Employee+Spouse rate. The sum of EE + EC will be the Employee+Child rate.
						</Text>
					</Grid>
				)}
			</Grid>
			<RatesTable form={form} data={form.values.planCoverages} />
		</Paper>
	)
}

export default RatesForm
