import { Link } from '@mui/material'
import { IDENTITY_CONFIG } from 'auth'
import Loading from 'components/loading'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Layout } from 'layouts'
import { Suspense, useEffect } from 'react'
import { AuthProvider, useAuth } from 'react-oidc-context'
import {
	Navigate,
	Outlet,
	RouteObject,
	RouterProvider,
	UIMatch,
	createBrowserRouter,
	useNavigate,
} from 'react-router-dom'
import { LocalStore } from 'utils'
import { useBenefitProviderRoutes } from './benefit-provider-routes'
import { DynamicBreadcrumb } from './breadcrumbs'
import { useConsistuentRoutes } from './constituent-routes'
import { useCorestreamConfigurationRoutes } from './corestream-configuration-routes'
import { useEmployeeProfileRoutes } from './employee-profile-routes'
import { useFileMonitoringRoutes } from './file-monitoring-routes'
import { useInvoiceRoutes } from './invoice-routes'
import { useOrgRoutes } from './organizations/useOrgRoutes'
import { useCorestreamProductsRoutes } from './product-types-routes'
import { useProductionSupportRoutes } from './production-support-routes'
import { routePaths } from './route-paths'
import { useDiscountOfferRoutes } from './useDiscountOfferRoutes'
import { prepareRoutes } from './utils/prepareRoutes'

const SetLoginRedirect = () => {
	if (!LocalStore.get('prevRoute')) {
		LocalStore.set('prevRoute', window.location.pathname)
	}
}

const AppRoutes = () => {
	const router = createBrowserRouter([
		{
			element: <AppLayout />,
			path: '*',
			children: [
				...unauthenticatedRoutes,
				{
					element: <AuthWrapper />,
					path: '*',
					children: [
						{
							path: '*',
							element: <Layout />,
							children: [
								...useProtectedRoutes(),
								{
									path: '*',
									element: <Navigate to={routePaths.organizations.root} />,
								},
							],
						},
					],
				},
			],
		},
	])

	return (
		<Suspense fallback={<Loading />}>
			<RouterProvider router={router} />
		</Suspense>
	)
}

export default AppRoutes

function AppLayout() {
	useEffect(() => {
		SetLoginRedirect()
	}, [])

	return (
		<AuthProvider
			{...IDENTITY_CONFIG}
			onSigninCallback={() => {
				// https://github.com/authts/react-oidc-context/blob/f175dcba6ab09871b027d6a2f2224a17712b67c5/src/AuthProvider.tsx#L20-L30
				window.history.replaceState({}, document.title, window.location.pathname)
			}}
		>
			<Outlet />
		</AuthProvider>
	)
}

function AuthWrapper() {
	const { isLoading: authIsLoading, isAuthenticated: authIsAuthenticated, clearStaleState } = useAuth()

	const navigate = useNavigate()

	useEffect(() => {
		if (!authIsLoading && !authIsAuthenticated) {
			clearStaleState()
			navigate(routePaths.login.path)
		}
	}, [authIsLoading, authIsAuthenticated, clearStaleState, navigate])

	if (authIsLoading) {
		return <span>Authenticating...</span>
	}

	if (authIsAuthenticated) {
		return <Outlet />
	}

	return <Navigate to='/login' replace />
}

const useBenefitRoutes = () => {
	return [useCorestreamProductsRoutes(), ...useBenefitProviderRoutes()]
}

const unauthenticatedRoutes: RouteObject[] = prepareRoutes(
	[
		{
			path: '/callback',
			lazy: () => import('pages/callback').then((module) => ({ Component: module.default })),
		},
		{
			path: '/unauthorized',
			lazy: () => import('pages/unauthorized').then((module) => ({ Component: module.Unauthorized })),
		},
		{
			path: '/login',
			lazy: () => import('pages/login').then((module) => ({ Component: module.default })),
		},
		{
			path: '/logout',
			lazy: () => import('pages/logout').then((module) => ({ Component: module.default })),
		},
		{
			path: '/reset-password',
			lazy: () => import('pages/reset-password').then((module) => ({ Component: module.default })),
		},
		{
			path: '/forgot-password',
			lazy: () => import('pages/send-reset-email').then((module) => ({ Component: module.default })),
		},
		{
			path: '/sandbox',
			lazy: () => import('pages/sandbox/sandbox').then((module) => ({ Component: module.default })),
		},
	],
	{ stripLeading: '/' },
)

const useProtectedRoutes = () => {
	const { wrp2798, transformers8737 } = useFlags()

	return prepareRoutes(
		[
			...useProductionSupportRoutes(),
			...useFileMonitoringRoutes(),
			...useInvoiceRoutes(),
			...useBenefitRoutes(),
			...useDiscountOfferRoutes(),
			...useOrgRoutes(),
			...useConsistuentRoutes(),
			...useCorestreamConfigurationRoutes(),
			{
				lazy: () => import('pages/refunds/refunds').then((module) => ({ Component: module.default })),
				flag: transformers8737,
				path: routePaths.refunds.root,
				handle: {
					crumb: () => <Link href={routePaths.refunds.root}>Refunds</Link>,
				},
			},
			{
				lazy: () => import('pages/create-employee-enrollment').then((module) => ({ Component: module.default })),
				flag: wrp2798,
				path: '/employee-search/tenant/:tenant/profile/:workerId/enrollments/create/:benefitPlanId',
			},
			{
				path: routePaths.employeeSearch.path,
				handle: {
					crumb: () => <Link href={routePaths.employeeSearch.path}>Employee Search</Link>,
				},
				children: [
					{
						index: true,
						lazy: () => import('pages/employee-search').then((module) => ({ Component: module.default })),
					},
					...prepareRoutes(
						[
							{
								lazy: () => import('pages/edit-employee-profile').then((module) => ({ Component: module.default })),
								path: `${routePaths.employeeSearch.tenant.profile.path}/*`,
								children: useEmployeeProfileRoutes(),
								handle: {
									crumb: (data: UIMatch<any, any>) => {
										return <DynamicBreadcrumb portalId={data.params.id ?? ''} />
									},
								},
							},
						],
						{
							stripLeading: `${routePaths.employeeSearch.path}/`,
						},
					),
				],
			},
			{
				lazy: () => import('pages/inbound-elections-file-lines').then((module) => ({ Component: module.default })),
				flag: transformers8737,
				path: routePaths.inboundElectionFiles.lines,
			},
		],
		{ stripLeading: '/' },
	)
}
