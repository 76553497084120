import { FormPlanTiers, RateFormTypeForPost, RatesFormType } from './types'

export const formatFormValues = (values: RatesFormType, planTiers: FormPlanTiers[]): RateFormTypeForPost => {
	if (values.notes === null) {
		values.notes = ''
	}

	const newObj: RateFormTypeForPost = {
		enrollmentEffectiveDate: '',
		notes: '',
		rateInfoId: '',
		rates: [],
		reference: '',
	}
	for (const key in values) {
		if (values.hasOwnProperty(key)) {
			if (key === 'planCoverages') {
				newObj['rates'] = planTiers
			} else {
				newObj[key] = values[key]
			}
		}
	}

	return newObj
}
