import React, { useEffect, useRef, useState } from 'react'
import { Chip, FormControl, FormLabel, Grid, TextField, makeStyles } from '@material-ui/core'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutline'

const useStyles = makeStyles({ root: { '&:hover': { color: 'black', cursor: 'pointer' } } })

interface ChipInputProps {
	name: string
	value: string[]
	label?: string
	error?: boolean
	errorMessage?: string
	disabled?: boolean
	id?: string
	onChange?: any
	required?: boolean
}

const FormChipInput: React.FC<ChipInputProps> = (props: ChipInputProps) => {
	const classes = useStyles()
	const ref = useRef<HTMLInputElement>(null)
	const [chipState, setChipState] = useState(props.value)
	const resetRef = () => {
		if (ref?.current?.value) {
			ref.current.value = ''
		}
	}

	const handleAddItem = (e: React.KeyboardEvent<HTMLDivElement> | null) => {
		if (e && e.key !== 'Enter') return
		if (ref?.current?.value) {
			setChipState([...chipState, ref.current.value])
			resetRef()
		}
	}

	//Filters off index. May want to tighten this up to have unique keys on form values?
	// Or possibly on the value itself if we can assume they're always unique.
	const handleDelete = (index: number) => {
		const newChips = chipState.filter((_, i) => i !== index)
		setChipState(newChips)
	}

	useEffect(() => {
		props.onChange({ target: { name: props.name, value: chipState } })
	}, [chipState, props])

	return (
		<FormControl component='fieldset'>
			<Grid container>
				<Grid item spacing={3} style={{ marginBottom: '5px' }}>
					<FormLabel required={props.required} component='legend'>
						{props.label}
					</FormLabel>
				</Grid>
				<Grid container>
					{chipState.length > 0 &&
						chipState.map((value, index) => (
							<Grid key={index} spacing={3} style={{ marginBottom: '5px', marginRight: '5px' }} item>
								<Chip label={value} onDelete={() => handleDelete(index)} />
							</Grid>
						))}
				</Grid>

				<Grid container>
					<Grid item spacing={3}>
						<TextField
							onKeyDown={(e) => handleAddItem(e)}
							required={props.required}
							error={props.error}
							helperText={props.errorMessage}
							disabled={props.disabled}
							inputRef={ref}
						/>
					</Grid>
					<Grid
						item
						spacing={3}
						onClick={() => {
							handleAddItem(null)
						}}
					>
						<AddCircleOutlineOutlinedIcon className={classes.root} color='action' />
					</Grid>
				</Grid>
			</Grid>
		</FormControl>
	)
}

FormChipInput.defaultProps = {
	error: false,
	id: '',
	label: '',
	required: false,
}

export default FormChipInput
