import { Checkbox as MuiCheckbox } from '@material-ui/core'
import React from 'react'

export interface CheckboxProps {
	ariaLabel?: string
	checked?: boolean
	className?: string
	color?: 'default' | 'primary' | 'secondary'
	disabled?: boolean
	name: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	value?: string
}

/**
 * Based on Material UI Checkbox
 * @see https://material-ui.com/components/checkboxes/#CheckboxLabels.tsx
 */
const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
	return (
		<MuiCheckbox
			className={props.className}
			color={props.color}
			checked={props.checked}
			disabled={props.disabled}
			inputProps={{ 'aria-label': props.ariaLabel }}
			name={props.name}
			onChange={props.onChange}
			value={props.value}
		/>
	)
}

Checkbox.defaultProps = {
	checked: false,
	color: 'primary',
	disabled: false,
}

export default Checkbox
