import { Link } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, UIMatch } from 'react-router-dom'
import { DynamicBreadcrumb } from './breadcrumbs'
import { routePaths } from './route-paths'
import { CsRoute } from './types'
import { prepareRoutes } from './utils/prepareRoutes'

export const useConsistuentRoutes = (): CsRoute[] => {
	const { transformers8737 } = useFlags()

	return [
		{
			path: `${routePaths.constituents.search.listing}/*`,
			flag: transformers8737,
			handle: {
				crumb: () => <Link href={routePaths.constituents.search.listing}>Constituent Search</Link>,
			},
			children: [
				{
					index: true,
					lazy: () =>
						import('pages/constituent-search/constituent-search').then((module) => ({ Component: module.default })),
				},
				...prepareRoutes(
					[
						{
							path: routePaths.constituents.search.profile.path,
							lazy: () =>
								import('pages/constituent-detail/constituent-detail').then((module) => ({ Component: module.default })),
							handle: {
								crumb: (data: UIMatch<any, any>) => <DynamicBreadcrumb portalId={data.params.id ?? ''} />,
							},
							children: prepareRoutes(
								[
									{
										path: '*',
										element: <Navigate to={'summary'} replace />,
									},
									{
										path: routePaths.constituents.search.profile.summary.path,
										lazy: () => import('pages/constituent-summary').then((module) => ({ Component: module.default })),
										handle: {
											crumb: () => <div>Summary</div>,
										},
									},
									{
										path: routePaths.constituents.search.profile.enrollments.enrollmentHistory.path,
										lazy: () =>
											import('pages/constituent-enrollment-history').then((module) => ({ Component: module.default })),
										handle: {
											crumb: () => <div>Enrollment History</div>,
										},
									},
									{
										path: routePaths.constituents.search.profile.enrollments.path,
										lazy: () =>
											import('pages/constituent-enrollments').then((module) => ({ Component: module.default })),
										handle: {
											crumb: () => <div>Enrollments</div>,
										},
									},
									{
										path: routePaths.constituents.search.profile.transactionHistory.path,
										lazy: () =>
											import('pages/employee-transaction-history').then((module) => ({ Component: module.default })),
										handle: {
											crumb: () => <div>Employee Transaction History</div>,
										},
									},
									{
										path: routePaths.constituents.search.profile.requestAmountHistory.path,
										lazy: () =>
											import('pages/employee-request-amount-history').then((module) => ({ Component: module.default })),
										handle: {
											crumb: () => <div>Request Amount History</div>,
										},
									},
									{
										path: routePaths.constituents.search.profile.censusHistory.path,
										lazy: () =>
											import('pages/constituent-workers-history').then((module) => ({
												Component: module.ConstituentWorkersHistory,
											})),
										handle: {
											crumb: () => <div>Census History</div>,
										},
									},
									{
										path: routePaths.constituents.search.profile.diagnostics.path,
										lazy: () =>
											import('pages/constituent-diagnostics').then((module) => ({ Component: module.default })),
										handle: {
											crumb: () => <div>Diagnostics</div>,
										},
									},
								],
								{
									stripLeading: `${routePaths.constituents.search.profile.path}/`,
								},
							),
						},
					],
					{
						stripLeading: `${routePaths.constituents.search.listing}/`,
					},
				),
			],
		},
	]
}
