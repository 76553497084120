const keys = {
	all: ['invoice-details'] as const,

	invoice: { getInvoiceData: (invoiceId: string) => [...keys.all, invoiceId, 'invoice-data'] as const },

	providerPayments: {
		getProviderPayments: (invoiceId: string) => [...keys.all, invoiceId, 'provider-payments-data'] as const,
	},
}

export { keys as invoiceDetailsQueryKeys }
