import { Action } from 'redux'

export const SNACKBAR_ERROR = 'snackbar/SNACKBAR_ERROR'
export const SNACKBAR_CLEAR = 'snackbar/SNACKBAR_CLEAR'
export const SNACKBAR_SUCCESS = 'snackbar/SNACKBAR_SUCCESS'
export type SnackbarError = typeof SNACKBAR_ERROR
export type SnackbarClear = typeof SNACKBAR_CLEAR
export type SnackbarSuccess = typeof SNACKBAR_SUCCESS

export interface SnackbarErrorAction extends Action {
	payload: string
	type: SnackbarError
}

export interface SnackbarClearAction extends Action {
	type: SnackbarClear
}

export interface SnackbarSuccessAction extends Action {
	payload: string
	type: SnackbarSuccess
}

export type SnackbarAction = SnackbarErrorAction | SnackbarSuccessAction | SnackbarClearAction
