export const APPROVE = 'approve'
export const AVAILABLE_FORMATS = 'AvailableFormats'
export const BILLING_FILE_CONFIGURATIONS = 'BillingFileConfigurations'
export const BILLING_FILES = 'BillingFiles'
export const BILLING_PERIODS = 'AvailableBillingPeriods'
export const CONSTITUENTS = 'constituents'
export const FILE_LINES = 'fileLines'
export const INVOICES = 'Invoices'
export const ORGANIZATIONS = 'Organizations'
export const RECONCILIATION_CONFIGURATIONS = 'ReconciliationConfigurations'
export const REJECT = 'reject'
export const SCHEDULE_TYPES = 'AvailableScheduleTypes'
