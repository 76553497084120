import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import DayjsUtils from '@date-io/dayjs'
import { FormControl } from '@material-ui/core'
import React from 'react'

/**
 * DatePicker API Props
 * @see https://material-ui-pickers.dev/api/DatePicker
 */
export type FormDatePickerProps = {
	autoOk?: boolean
	clearable?: boolean
	disabled?: boolean
	error?: boolean
	errorMessage?: string
	format?: string
	helperText?: string
	id?: string
	label: any
	onChange: (date: any) => void
	maxDate?: Date | string
	minDate?: Date | string
	name: string
	required?: boolean
	value: Date | string | null
	variant?: 'dialog' | 'inline' | 'static'
	labelPlacement?: 'bottom' | 'end' | 'start' | 'top'
	inputVariant?: 'standard' | 'outlined' | 'filled'
	formControlStyle?: React.CSSProperties
	onBlur?: (event: any) => void
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
	autoOk = true,
	clearable,
	disabled,
	error,
	errorMessage,
	format,
	formControlStyle,
	helperText,
	id,
	inputVariant,
	label,
	maxDate,
	minDate,
	name,
	onBlur,
	onChange,
	required,
	value,
	variant,
}: FormDatePickerProps) => {
	const handleChange = (_, value?: string | null): void => onChange({ target: { name, value: value } })

	return (
		<MuiPickersUtilsProvider utils={DayjsUtils}>
			<FormControl error={error} required={required} style={formControlStyle}>
				<KeyboardDatePicker
					autoOk={autoOk}
					clearable={clearable}
					disabled={disabled}
					error={error}
					format={format}
					helperText={(error && errorMessage) ?? helperText}
					id={id}
					InputLabelProps={{ shrink: true }}
					inputVariant={inputVariant}
					label={label}
					maxDate={maxDate}
					minDate={minDate}
					name={name}
					onChange={handleChange}
					value={value}
					variant={variant}
					required={required}
					onBlur={onBlur}
				/>
			</FormControl>
		</MuiPickersUtilsProvider>
	)
}

FormDatePicker.defaultProps = {
	error: false,
	inputVariant: 'filled',
	required: false,
	variant: 'inline',
}

export default FormDatePicker
