import { Reducer } from 'redux'
import { LOGIN_ERROR, LOGIN_PENDING, LOGIN_SUCCESS, LoginAction, UserState } from './types'

const initialState: UserState = {
	expiresIn: 0,
	isLoggedOut: false,
	isLoginPending: false,
	isUserLoading: false,
	loginError: null,
	token: '',
	tokenType: '',
	user: {},
}

export const user: Reducer<UserState, LoginAction> = (state = initialState, action: LoginAction): UserState => {
	switch (action.type) {
		case LOGIN_ERROR:
			return { ...state, isLoginPending: false, loginError: action.payload }
		case LOGIN_PENDING:
			return { ...state, isLoginPending: true }
		case LOGIN_SUCCESS:
			return {
				...state,
				expiresIn: action.payload.expires_in,
				isLoginPending: false,
				loginError: null,
				token: action.payload.access_token,
				tokenType: action.payload.token_type,
			}
		// case 'LOL':
		// 	return { ...state, isLoggedOut: true }
		default:
			return { ...state }
	}
}
