import { authConstants } from 'constants/auth-constants'
import { WebStorageStateStore } from 'oidc-client-ts'
import { AuthProviderProps } from 'react-oidc-context'
import UrlBuilder from 'services/util/url-builder'
import { API_NAME as AUTH_API_NAME } from '../api/auth/constants'

const url = `${window.location.protocol}//${window.location.hostname}${
	window.location.port ? `:${window.location.port}` : ''
}`
const authUrl: string = new UrlBuilder(AUTH_API_NAME).url()

export const IDENTITY_CONFIG: AuthProviderProps = {
	userStore: new WebStorageStateStore({ store: window.localStorage }),
	extraHeaders: {
		Tenant: 'corestream',
	},
	acr_values: 'tenant:corestream',
	authority: authUrl,
	automaticSilentRenew: true,
	client_id: authConstants.clientId,

	includeIdTokenInSilentRenew: true,
	loadUserInfo: true,
	metadata: {
		authorization_endpoint: `${authUrl}/connect/authorize`,
		end_session_endpoint: `${authUrl}/connect/endsession`,
		issuer: authUrl,
		jwks_uri: `${authUrl}/.well-known/openid-configuration/jwks`,
		token_endpoint: `${authUrl}/connect/token`,
		userinfo_endpoint: `${authUrl}/connect/userinfo`,
	},
	post_logout_redirect_uri: `${url}/logout`,
	prompt: 'none',
	redirect_uri: `${url}/callback`,
	response_mode: 'query', // TODO maybe delete this
	response_type: 'code',
	revokeTokensOnSignout: true,
	scope:
		'openid tenants_api profile enrollments_api census_api billing_api offline_access support_api ' +
		'benefitElections_api files_api constituents_api outboundelection_files billfileprocessor_api banking_api ' +
		'outboundpayments_api inboundelections_api paychex_integration payrolldeduction_api outboundcensus_api',
	silentRequestTimeoutInSeconds: 10000,
	silent_redirect_uri: `${url}/callback`,
	staleStateAgeInSeconds: 60,
}
