import { Link } from '@mui/material'
import { Navigate, UIMatch, generatePath } from 'react-router-dom'
import { DynamicBreadcrumb } from 'routes/breadcrumbs'
import { routePaths } from 'routes/route-paths'
import { CsRoute } from 'routes/types'
import { useOrgBenefitPlanEditRateNestedRoutes } from 'routes/useOrgBenefitPlanEditRateNestedRoutes'
import { prepareRoutes } from 'routes/utils/prepareRoutes'

export const useOrgBenefitPlanRoutes = (): CsRoute => {
	return {
		path: `${routePaths.organizations.edit.benefitPlans.path}/*`,
		handle: {
			crumb: (data: UIMatch<unknown, unknown>) => {
				return (
					<Link
						href={generatePath(routePaths.organizations.edit.benefitPlans.path, {
							id: data.params.id ?? '',
						})}
					>
						Benefit Plans
					</Link>
				)
			},
		},
		children: [
			{
				index: true,
				lazy: () =>
					import('pages/benefit-plans-v2').then((module) => ({
						Component: module.default,
					})),
			},
			...prepareRoutes(
				[
					{
						lazy: () => import('pages/create-rate-info').then((module) => ({ Component: module.default })),
						path: routePaths.organizations.edit.benefitPlans.rates.clone.path,
					},
					{
						lazy: () => import('routes/benefit-plan-routes').then((module) => ({ Component: module.default })),
						path: `${routePaths.organizations.edit.benefitPlans.edit.path}/*`,
						children: prepareRoutes(
							[
								{
									path: `*`,
									element: <Navigate to='details' replace />,
								},
								{
									path: routePaths.organizations.edit.benefitPlans.details.path,
									lazy: () =>
										import('pages/edit-benefit-plan-v2').then((module) => ({
											Component: module.default,
										})),
								},
								{
									path: `${routePaths.organizations.edit.benefitPlans.marketingContent.path}/*`,
									handle: {
										crumb: (data: UIMatch<any, any>) => (
											<Link
												href={generatePath(routePaths.organizations.edit.benefitPlans.marketingContent.path, {
													benefitPlanId: data.params.benefitPlanId ?? '',
													id: data.params.id ?? '',
												})}
											>
												Marketing Content
											</Link>
										),
									},
									children: prepareRoutes(
										[
											{
												index: true,
												lazy: () =>
													import('pages/benefit-plan-marketing-contents').then((module) => ({
														Component: module.default,
													})),
											},
											// Benefit Plan Marketing Content
											{
												lazy: () =>
													import('pages/edit-benefit-plan-marketing-content').then((module) => ({
														Component: module.default,
													})),
												path: routePaths.organizations.edit.benefitPlans.marketingContent.edit.path,
												handle: {
													crumb: (data) => <DynamicBreadcrumb portalId={data.params.marketingContentId ?? ''} />,
												},
											},
											{
												lazy: () =>
													import('pages/create-benefit-plan-marketing-content').then((module) => ({
														Component: module.default,
													})),
												path: routePaths.organizations.edit.benefitPlans.marketingContent.create.path,
											},
										],
										{
											stripLeading: `${routePaths.organizations.edit.benefitPlans.marketingContent.path}/`,
										},
									),
								},
								{
									path: `${routePaths.organizations.edit.benefitPlans.rates.path}/*`,
									handle: {
										crumb: (data: UIMatch<any, any>) => (
											<Link
												href={generatePath(routePaths.organizations.edit.benefitPlans.rates.path, {
													benefitPlanId: data.params.benefitPlanId ?? '',
													id: data.params.id ?? '',
												})}
											>
												Rates
											</Link>
										),
									},
									children: prepareRoutes(
										[
											{
												index: true,
												lazy: () =>
													import('pages/rates').then((module) => ({
														Component: module.default,
													})),
											},
											{
												lazy: () =>
													import('routes/edit-rates-routes').then((module) => ({
														Component: module.default,
													})),
												path: routePaths.organizations.edit.benefitPlans.rates.edit.path,
												children: useOrgBenefitPlanEditRateNestedRoutes(),
												handle: {
													crumb: (data: UIMatch<any, any>) => {
														return <DynamicBreadcrumb portalId={data.params.rateInfoId ?? ''} />
													},
												},
											},
											{
												lazy: () =>
													import('pages/create-rate-info').then((module) => ({
														Component: module.default,
													})),
												path: routePaths.organizations.edit.benefitPlans.rates.create.path,
											},
										],
										{
											stripLeading: `${routePaths.organizations.edit.benefitPlans.rates.path}/`,
										},
									),
								},
							],
							{
								stripLeading: `${routePaths.organizations.edit.benefitPlans.edit.path}/`,
							},
						),
						handle: {
							crumb: (data: UIMatch<unknown, unknown>) => (
								<DynamicBreadcrumb portalId={data.params.benefitPlanId ?? ''} />
							),
						},
					},
					{
						lazy: () => import('pages/create-benefit-plan-v2').then((module) => ({ Component: module.default })),
						path: routePaths.organizations.edit.benefitPlans.create.path,
					},
				],
				{
					stripLeading: `${routePaths.organizations.edit.benefitPlans.path}/`,
				},
			),
		],
	}
}
