import { Action } from 'redux'
import { Token } from 'types/login-types'

export const LOGIN_ERROR = 'user/LOGIN_ERROR'
export const LOGIN_PENDING = 'user/LOGIN_PENDING'
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS'
export type LoginError = typeof LOGIN_ERROR
export type LoginLoading = typeof LOGIN_PENDING
export type LoginSuccess = typeof LOGIN_SUCCESS
// export type SessionTerminated = typeof SESSION_TERMINATED

export interface LoginErrorAction extends Action {
	payload: Error
	type: LoginError
}

export interface LoginPendingAction extends Action {
	type: LoginLoading
}

export interface LoginSuccessAction extends Action {
	payload: Token
	type: LoginSuccess
}

export type LoginAction = LoginErrorAction | LoginPendingAction | LoginSuccessAction
export type UserAction = LoginAction

export type UserState = Readonly<{
	expiresIn: number
	isLoginPending: boolean
	isLoggedOut: boolean
	isUserLoading: boolean
	loginError: Error | null
	token: string
	tokenType: string
	user: Record<string, unknown>
}>
