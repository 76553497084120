import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { StateMachineStatus } from 'hooks/use-async-fetch'

const MarginLeftDiv = styled.div<{ marginLeft: number }>`
	margin-left: ${(props: { marginLeft: number }) => props.marginLeft}px;
`

type RightSpacingDivProps = {
	omitExtraRightSpacing?: boolean
	shouldShow: boolean
}

const RightSpacingDiv = styled.div<RightSpacingDivProps>`
	display: ${(props: RightSpacingDivProps) => (props.omitExtraRightSpacing ? 'hide' : 'show')};
	margin-left: 0.5rem;
	visibility: ${(props: RightSpacingDivProps) => (props.shouldShow ? 'inherit' : 'hidden')};
	width: 1rem;
`

export type FormInputProps = {
	autoFocus?: boolean
	className?: string
	disabled?: boolean
	error?: boolean
	errorMessage?: string | string[]
	extraText?: string
	helperText?: string
	id?: string
	label: any
	maxLength?: number
	minLength?: number
	name: string
	onBlur?: (...args: any[]) => any
	onChange: any
	onFocus?: (...args: any[]) => any
	required?: boolean
	shouldVerify?: boolean
	type?: string
	value: any
	variant?: 'standard' | 'outlined' | 'filled'
	verifyCallback?: () => any
	verifyStatus?: StateMachineStatus
	verifyValue?: boolean | null
	fullWidth?: boolean
	omitExtraRightSpacing?: boolean
	InputProps?: any
	InputLabelProps?: any
}

const ExtraText = (props: Pick<FormInputProps, 'extraText'>) => {
	if (props.extraText) {
		return null
	}

	return (
		<Grid xs={2} item>
			<MarginLeftDiv marginLeft={12}>{props.extraText}</MarginLeftDiv>
		</Grid>
	)
}
/**
 * Based on composable TextField
 * @see https://material-ui.com/components/text-fields/#ComposedTextField.tsx
 */
const FormInput: React.FC<FormInputProps> = ({
	autoFocus,
	className = '',
	error = false,
	errorMessage = '',
	extraText,
	fullWidth = true,
	helperText,
	id = '',
	label,
	maxLength = 255,
	minLength = 0,
	name,
	omitExtraRightSpacing,
	onBlur,
	onChange,
	onFocus,
	required = false,
	shouldVerify,
	type = 'text',
	value,
	variant = 'filled',
	verifyCallback,
	verifyStatus,
	verifyValue,
	InputProps,
	InputLabelProps,
	...props
}: FormInputProps) => {
	const onBlurEvent = (e): void => {
		if (onBlur) {
			onBlur(e)
		}

		if (shouldVerify && verifyCallback && value) {
			verifyCallback()
		}
	}

	const getHelperText = (): string | JSX.Element[] | undefined => {
		if (errorMessage && Array.isArray(errorMessage)) {
			return errorMessage.map((err, index) => (
				<span className='reset-errors' key={index}>
					{err}
				</span>
			))
		} else if (errorMessage) {
			return errorMessage
		}

		return helperText
	}

	return (
		<Grid alignItems='center' justifyContent='space-between' container>
			<Grid xs={extraText ? 10 : 12} item>
				<TextField
					autoFocus={autoFocus}
					className={className}
					error={error}
					helperText={getHelperText()}
					id={id}
					inputProps={{ 'aria-label': name, maxLength, minLength }}
					label={label}
					name={name}
					onBlur={onBlurEvent}
					onChange={onChange}
					onFocus={onFocus}
					type={type}
					value={value}
					variant={variant}
					required={required}
					fullWidth={fullWidth}
					InputProps={InputProps}
					InputLabelProps={InputLabelProps}
					{...props}
				/>
			</Grid>
			<ExtraText extraText={extraText} />
			<RightSpacingDiv
				omitExtraRightSpacing={omitExtraRightSpacing}
				shouldShow={Boolean(shouldVerify && verifyStatus && verifyValue !== null)}
			/>
		</Grid>
	)
}

export default FormInput
