// ----------------------------------------------------------------------

export function remToPx(value: string) {
	return Math.round(parseFloat(value) * 14)
}

export function pxToRem(value: number) {
	return `${value / 14}rem`
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
	return {
		'@media (min-width:600px)': {
			fontSize: pxToRem(sm),
		},
		'@media (min-width:900px)': {
			fontSize: pxToRem(md),
		},
		'@media (min-width:1200px)': {
			fontSize: pxToRem(lg),
		},
	}
}

declare module '@mui/material/styles' {
	interface TypographyVariants {
		fontSecondaryFamily: React.CSSProperties['fontFamily']
		fontWeightSemiBold: React.CSSProperties['fontWeight']
	}
}

export const primaryFont = 'var(--font-family)'
export const secondaryFont = 'var(--font-family)'

// ----------------------------------------------------------------------

export const typography = {
	fontFamily: primaryFont,
	fontSecondaryFamily: secondaryFont,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightSemiBold: 600,
	fontWeightBold: 700,
	h1: {
		fontWeight: 300,
		// lineHeight: 1.5,
		fontSize: pxToRem(34.84),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 34.84, md: 42.72, lg: 42.72 }),
	},
	h2: {
		fontWeight: 700,
		// lineHeight: 1.5,
		fontSize: pxToRem(24.19),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 24.19, md: 27.34, lg: 27.34 }),
	},
	h3: {
		fontWeight: 400,
		// lineHeight: 1.5,
		fontSize: pxToRem(20.16),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 20.16, md: 21.88, lg: 21.88 }),
	},
	['h3-bold']: {
		fontWeight: 700,
		lineHeight: 1.5,
		fontSize: pxToRem(21.88),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 20.16, md: 21.88, lg: 21.88 }),
	},
	h4: {
		fontWeight: 600,
		// lineHeight: 1.5,
		fontSize: pxToRem(18),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 18, md: 20, lg: 20 }),
	},
	['h4-body']: {
		fontWeight: 400,
		// lineHeight: 1.5,
		fontSize: pxToRem(18),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 18, md: 20, lg: 20 }),
	},
	h5: {
		fontWeight: 600,
		// lineHeight: 1.5,
		fontSize: pxToRem(15),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 15, md: 17.5, lg: 17.5 }),
	},
	['h5-body']: {
		fontWeight: 400,
		// lineHeight: 1.5,
		fontSize: pxToRem(15),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 15, md: 17.5, lg: 17.5 }),
	},
	h6: {
		fontWeight: 600,
		// lineHeight: 28 / 18,
		fontSize: pxToRem(18),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
	},
	['h6-body']: {
		fontWeight: 400,
		lineHeight: 1.5,
		fontSize: pxToRem(16),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 14, md: 15, lg: 16 }),
	},
	subtitle1: {
		fontWeight: 600,
		lineHeight: 1.5,
		fontSize: pxToRem(16),
		fontFamily: secondaryFont,
		...responsiveFontSizes({ sm: 14, md: 15, lg: 16 }),
	},
	subtitle2: {
		fontWeight: 600,
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
	},
	body1: {
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
		// ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
	},
	body2: {
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
	},
	caption: {
		// lineHeight: 1.5,
		fontSize: pxToRem(12),
	},
	overline: {
		fontWeight: 700,
		// lineHeight: 1.5,
		fontSize: pxToRem(12),
		textTransform: 'uppercase',
	},
	button: {
		fontWeight: 700,
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
		textTransform: 'capitalize',
	},
} as const
