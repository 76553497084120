import { Icon, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { clearSnackbar } from 'store/snackbar/actions'

// TODO: Rename back to snackbar once the rest of app is refactored
const SuccessSnackbar = (): JSX.Element => {
	const dispatch = useDispatch()

	const { snackbarMessage, snackbarOpen, snackbarStatus } = useSelector((state: RootState) => state.uiReducer)

	function handleClose() {
		dispatch(clearSnackbar())
	}

	return (
		<Snackbar
			anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
			open={snackbarOpen}
			autoHideDuration={5000}
			onClose={handleClose}
			aria-describedby='client-snackbar'
			message={
				<span id='client-snackbar'>
					<Icon>check_circle</Icon>
					{snackbarMessage}
				</span>
			}
		>
			<Alert onClose={handleClose} severity={snackbarStatus}>
				{snackbarMessage}
			</Alert>
		</Snackbar>
	)
}

export default SuccessSnackbar
