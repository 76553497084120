import { Link } from '@mui/material'
import { Navigate, Outlet, UIMatch } from 'react-router-dom'
import { DynamicBreadcrumb } from './breadcrumbs'
import { routePaths } from './route-paths'
import { CsRoute } from './types'
import { prepareRoutes } from './utils/prepareRoutes'

export const useCorestreamProductsRoutes = (): CsRoute => {
	// flags
	const routes = prepareRoutes(
		[
			{
				lazy: () =>
					import('routes/edit-corestream-products-routes').then((module) => ({
						Component: module.EditCorestreamProductsRoutes,
					})),
				path: routePaths.productTypes.edit.root,
				children: prepareRoutes(
					[
						{
							path: '*',
							element: <Navigate to={'details'} replace />,
						},
						{
							path: routePaths.productTypes.edit.details.path,
							lazy: () => import('pages/edit-corestream-products').then((module) => ({ Component: module.default })),
						},
						{
							path: routePaths.productTypes.edit.tokens.path,
							lazy: () => import('pages/edit-product-type-tokens').then((module) => ({ Component: module.default })),
						},
					],
					{
						stripLeading: `${routePaths.productTypes.edit.root}/`,
					},
				),
				handle: {
					crumb: (data: UIMatch<any, any>) => <DynamicBreadcrumb portalId={data.params.corestreamProductId ?? ''} />,
				},
			},
			{
				index: true,
				lazy: () => import('pages/corestream-products').then((module) => ({ Component: module.default })),
			},
		],
		{ stripLeading: `${routePaths.productTypes.root}/` },
	)

	return {
		path: routePaths.productTypes.root,
		element: <Outlet />,
		handle: {
			crumb: () => <Link href={routePaths.productTypes.root}>Product Types</Link>,
		},
		children: routes,
	}
}
