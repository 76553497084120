import { CircularProgress } from '@material-ui/core'
import React from 'react'

interface LoadingProps {
	className?: string
	color?: 'inherit' | 'primary' | 'secondary'
	size?: number | string
	thickness?: number
	value?: number
	variant?: 'determinate' | 'indeterminate'
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
	return (
		<CircularProgress
			color={props.color}
			className={props.className}
			size={props.size}
			value={props.value}
			variant={props.variant}
			style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
		/>
	)
}

Loading.defaultProps = {
	color: 'primary',
	size: 40,
	value: 0,
	variant: 'indeterminate',
}

export default Loading
