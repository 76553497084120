import { Link } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { invoiceDetailsQueryKeys } from 'pages/invoice-reconciliation-details/query-keys'
import { useQuery } from 'react-query'
import { Outlet, UIMatch, generatePath, useParams } from 'react-router-dom'
import InvoicesService from 'services/invoices-service'
import { InvoiceReconciliationDetailsResponse } from 'types/invoices'
import { ProblemResponse } from 'types/response-types'
import { DynamicBreadcrumb, useBreadcrumb } from './breadcrumbs'
import { routePaths } from './route-paths'
import { CsRoute } from './types'
import { prepareRoutes } from './utils/prepareRoutes'

export const useInvoiceRoutes = (): CsRoute[] => {
	const { transformers8737, transformers10884 } = useFlags()

	return [
		{
			path: `${routePaths.invoices.listing}/*`,
			flag: transformers8737,
			handle: {
				crumb: () => <Link href={routePaths.invoices.listing}>Invoices</Link>,
			},
			children: [
				{
					index: true,
					lazy: () => import('pages/invoices').then((module) => ({ Component: module.default })),
				},
				...prepareRoutes(
					[
						{
							path: `${routePaths.invoices.details}/*`,
							element: <InvoiceLayout />,
							handle: {
								crumb: (data: UIMatch<any, any>) => <DynamicBreadcrumb portalId={data.params.id ?? ''} />,
							},
							children: [
								{
									index: true,
									lazy: () =>
										import('pages/invoice-reconciliation-details').then((module) => ({ Component: module.default })),
								},
								...prepareRoutes(
									[
										{
											path: routePaths.invoices.discrepancies,
											lazy: () =>
												import('pages/invoice-discrepancies/invoice-discrepancies').then((module) => ({
													Component: module.default,
												})),
											handle: {
												crumb: (data: UIMatch<any, any>) => (
													<Link
														href={generatePath(routePaths.invoices.discrepancies, {
															id: data.params.id ?? '',
														})}
													>
														Discrepancies
													</Link>
												),
											},
										},
										{
											path: routePaths.invoices.remittance.path,
											flag: transformers10884,
											lazy: () =>
												import('pages/invoice-remittance-details').then((module) => ({ Component: module.default })),
											handle: {
												crumb: (data: UIMatch<any, any>) => (
													<Link
														href={generatePath(routePaths.invoices.remittance.path, {
															id: data.params.id ?? '',
														})}
													>
														Remittance Details
													</Link>
												),
											},
										},
										{
											path: routePaths.invoices.payrollPayments.details,
											lazy: () =>
												import('pages/invoice-payroll-payments').then((module) => ({ Component: module.default })),
										},
									],
									{
										stripLeading: `${routePaths.invoices.details}/`,
									},
								),
							],
						},
					],
					{
						stripLeading: `${routePaths.invoices.listing}/`,
					},
				),
			],
		},
	]
}

const InvoiceLayout = () => {
	// load data and do breadcrumb
	const { id } = useParams<{ id: string }>()

	const { CrumbPortalRef, updateCrumbValue } = useBreadcrumb(id)

	useQuery<InvoiceReconciliationDetailsResponse | undefined, ProblemResponse>(
		invoiceDetailsQueryKeys.invoice.getInvoiceData(id!),
		() => InvoicesService.getInvoiceById({ invoiceId: id! }),
		{
			onSuccess: (data) =>
				data &&
				updateCrumbValue(
					<Link
						href={generatePath(routePaths.invoices.details, {
							id: data.id ?? '',
						})}
					>
						{data.invoiceNumber}
					</Link>,
				),
		},
	)

	return (
		<>
			{CrumbPortalRef.current}
			<Outlet />
		</>
	)
}
