import {
	DashboardOutlined,
	ExpandMore,
	LocalHospitalOutlined,
	LocalOfferOutlined,
	LocationCityOutlined,
	PeopleAltOutlined,
	SettingsOutlined,
} from '@mui/icons-material'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Drawer,
	Hidden,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routePaths } from 'routes/route-paths'
import UrlBuilder from 'services/util/url-builder'

interface SideNavProps {
	handleDrawerToggle: () => void
	open: boolean
}

interface NavRoute {
	icon: JSX.Element
	name: string
	link: string
	nestedNav?: NestedNav[]
	flag?: boolean
}

interface NestedNav {
	name: string
	link: string
	flag?: boolean
}

const userUrlBuilder: UrlBuilder = new UrlBuilder('authui')
export const drawerWidth = 220
const offWhite = '#eee'

// Component
const SideNav: React.FC<SideNavProps> = ({ handleDrawerToggle, open }: SideNavProps) => {
	const { transformers8737 } = useFlags()

	const navigate = useNavigate()
	// const classes = useStyles()

	const navRoutes: NavRoute[] = [
		{ icon: <LocationCityOutlined />, link: '/organizations', name: 'Organizations' },
		{ icon: <LocalOfferOutlined />, link: '/discounters', name: 'Discounts' },
		{
			icon: <LocalHospitalOutlined />,
			link: '',
			name: 'Benefits',
			nestedNav: [
				{
					link: '/benefit-providers',
					name: 'Benefit Providers',
				},
				{
					link: '/product-types',
					name: 'Product Types',
				},
			],
		},
		{
			flag: transformers8737,
			icon: <SettingsOutlined />,
			link: routePaths.corestreamConfigurations.root,
			name: 'Corestream Configurations',
		},
		{ icon: <PeopleAltOutlined />, link: userUrlBuilder.setEndpoint('/en-GB/users').url(), name: 'User Manager' },
		{
			icon: <DashboardOutlined />,
			link: '',
			name: 'Production Support',
			nestedNav: [
				{
					link: routePaths.productionSupport.root,
					name: 'Dashboard',
				},
				{
					link: routePaths.employeeSearch.path,
					name: 'Employee Search',
				},
				{
					flag: transformers8737,
					link: routePaths.constituents.search.listing,
					name: 'Constituent Search',
				},
				{
					flag: transformers8737,
					link: routePaths.invoices.listing,
					name: 'Invoices',
				},
				{
					flag: transformers8737,
					link: routePaths.refunds.root,
					name: 'Refund Summary',
				},
				{
					link: routePaths.fileMonitoring.root,
					name: 'File Monitoring',
				},
			],
		},
	]

	const drawerContent = (
		<>
			<div style={{ marginTop: 64 }} />
			<List>
				{navRoutes
					.filter((x) => x.flag === undefined || x.flag === true)
					.map((nav) =>
						nav.nestedNav ? (
							<ListItemButton key={nav.name}>
								<Accordion
									style={{
										backgroundColor: offWhite,
										border: 'none',
										boxShadow: 'none',
										marginLeft: '-20px',
										paddingBottom: '0px',
										paddingTop: '0px',
									}}
								>
									<AccordionSummary expandIcon={<ExpandMore />}>
										<ListItemIcon>{nav.icon}</ListItemIcon>
										<ListItemText primary={nav.name} />
									</AccordionSummary>
									{nav.nestedNav
										.filter((x) => x.flag === undefined || x.flag === true)
										.map((nestedItem) => {
											return (
												<Tooltip title={`View ${nestedItem.name}`} key={nestedItem.name}>
													<AccordionDetails>
														<ListItemIcon>{/* Leave icon blank, use tag for spacing */}</ListItemIcon>
														<ListItemText
															primary={nestedItem.name}
															onClick={(): void => toggleDrawerOnSelect(nestedItem.link)}
														/>
													</AccordionDetails>
												</Tooltip>
											)
										})}
								</Accordion>
							</ListItemButton>
						) : (
							<Tooltip title={`View ${name}`} key={nav.name}>
								<ListItem button>
									<ListItemIcon>{nav.icon}</ListItemIcon>
									<ListItemText primary={nav.name} onClick={(): void => toggleDrawerOnSelect(nav.link)} />
								</ListItem>
							</Tooltip>
						),
					)}
			</List>
		</>
	)

	function toggleDrawerOnSelect(link: string): void {
		if (link.includes('https')) {
			window.location = link as unknown as Location // Necessary to convert to location type
		} else {
			navigate(link)
		}
	}

	return (
		<nav aria-label='Admin Navigation'>
			<Hidden mdUp implementation='js'>
				<Drawer
					sx={{
						flexShrink: 0,
						width: drawerWidth,
						[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
						'& .MuiAccordion-root': {
							backgroundColor: offWhite,
							border: 'none',
							boxShadow: 'none',
							marginLeft: '-20px',
							paddingBottom: '0px',
							paddingTop: '0px',
						},
						'& .MuiAccordionSummary-content': { margin: '0px' },
						'& .MuiAccordionDetails-root': { paddingLeft: '20' },
					}}
					variant='temporary'
					open={open}
					onClose={handleDrawerToggle}
					ModalProps={{ keepMounted: true }}
				>
					{drawerContent}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation='css'>
				<Drawer
					sx={{
						flexShrink: 0,
						width: drawerWidth,
						[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
						'& .MuiAccordion-root': {
							backgroundColor: offWhite,
							border: 'none',
							boxShadow: 'none',
							marginLeft: '-20px',
							paddingBottom: '0px',
							paddingTop: '0px',
						},
						'& .MuiAccordionSummary-content': { margin: '0px' },
						'& .MuiAccordionDetails-root': { paddingLeft: '20' },
					}}
					variant='permanent'
					anchor='left'
					open
				>
					{drawerContent}
				</Drawer>
			</Hidden>
		</nav>
	)
}

export default SideNav
