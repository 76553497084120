import { Dialog, Theme, WithStyles, createStyles, styled, withStyles } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

const styles = (theme: Theme) => {
	return createStyles({
		closeButton: {
			color: theme.palette.grey[500],
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
	})
}

interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string
	children: React.ReactNode
	onClose: () => void
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...rest } = props

	return (
		<MuiDialogTitle disableTypography className={classes.root} {...rest}>
			<DialogHeading variant='h2'>{children}</DialogHeading>
			{onClose ? (
				<IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogActions)

interface ModalProps {
	actions?: React.ReactNode
	children: React.ReactNode
	className?: string
	disableBackdropClick?: boolean
	disableEnforceFocus?: boolean
	fullWidth?: boolean
	handleClose: () => void
	id: string
	maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined
	open: boolean
	title: string
}

/**
 * Based off Material UI Dialogs
 * @see https://material-ui.com/components/dialogs/#AlertDialog.tsx
 */
const Modal: React.FC<ModalProps> = ({
	actions,
	children,
	className,
	disableBackdropClick,
	disableEnforceFocus,
	fullWidth,
	handleClose,
	id,
	maxWidth,
	open,
	title,
}: ModalProps) => {
	return (
		<Dialog
			aria-labelledby={id}
			className={className}
			disableBackdropClick={disableBackdropClick}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			onClose={handleClose}
			open={open}
			disableEnforceFocus={disableEnforceFocus}
		>
			<DialogTitle id={id} onClose={handleClose}>
				{title}
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
			{actions ? <DialogActions>{actions}</DialogActions> : null}
		</Dialog>
	)
}

export default Modal

const DialogHeading = styled(Typography)({
	fontSize: '1.325rem',
	fontWeight: 500,
})
