import { CKEditor } from 'ckeditor4-react'
import { cx } from 'class-variance-authority'
import Text from 'components/text'
import React from 'react'
import { useAuth } from 'react-oidc-context'
import UrlBuilder from 'services/util/url-builder'
import { match } from 'ts-pattern'
import { formatBulletedList } from 'utils'
import styles from './form-rich-text.module.css'

interface FormRichTextProps {
	className?: string
	errorMessage?: string
	hasError?: boolean
	id?: string
	label?: string
	onBlur?: any
	onChange: any
	required?: boolean
	value: string | null
	readonly?: boolean
	useSmallTextLabel?: boolean
	entityIdForFileUpload?: string
	isTextOnly?: boolean
	bareBones?: boolean
	isBulletedOnly?: boolean
}

type ToolbarItem = {
	items: Array<string>
	name: string
}

const urlBuilder: UrlBuilder = new UrlBuilder()

const FormRichText: React.FC<FormRichTextProps> = (props: FormRichTextProps) => {
	const { user } = useAuth()

	/**
	 * When the field is bulleted only, this will ensure that when leaving the field that the content is transformed into
	 * an unordered list
	 */
	const onEditorChange = (event) => {
		if (!props.isBulletedOnly) return

		const data = event.editor.getData()
		// Transform content to bulleted list if not already
		if (!data.startsWith('<ul>') || !data.endsWith('</ul>')) {
			event.editor.setData(`<ul>${formatBulletedList(data)}</ul>`)
		}
	}

	/**
	 * When the field is bulleted only, this will ensure that when text is pasted into the content it is then transformed
	 * into an unordered list
	 */
	const onPaste = (event) => {
		if (!props.isBulletedOnly) return

		event.data.dataValue = `<ul>${formatBulletedList(event.data.dataValue)}</ul>`
	}

	const formToolbarItem = (name, items: Array<string>): ToolbarItem => ({ items: [...items], name })
	// eslint-disable-next-line max-len
	const embedProvider = `//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}&api_key=${
		import.meta.env.VITE_IFRAMELY_API_KEY
	}`
	const toolbar: Array<ToolbarItem | string> = [
		formToolbarItem('clipboard', ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']),
		formToolbarItem('editing', ['Scayt']),
		formToolbarItem('links', ['Link', 'Unlink', 'Anchor']),
		formToolbarItem('insert', ['Image', 'Embed', 'Table', 'HorizontalRule', 'SpecialChar']),
		formToolbarItem('tools', ['Maximize']),
		formToolbarItem('document', ['Source']),
		'/',
		formToolbarItem('basicstyles', [
			'Font',
			'FontSize',
			'Bold',
			'Italic',
			'Underline',
			'Strike',
			'Subscript',
			'Superscript',
		]),
		formToolbarItem('colors', ['TextColor', 'BGColor']),
		'/',
		formToolbarItem('align', ['JustifyLeft', 'JustifyCenter', 'JustifyRight']),
		formToolbarItem('paragraph', ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'Size']),
		formToolbarItem('styles', ['Styles', 'Format']),
		formToolbarItem('about', ['About']),
	]
	const textOnlyToolbar: Array<ToolbarItem | string> = [
		formToolbarItem('clipboard', ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']),
		formToolbarItem('editing', ['Scayt']),
		formToolbarItem('links', ['Link', 'Unlink', 'Anchor']),
		formToolbarItem('tools', ['Maximize']),
		'/',
		formToolbarItem('basicstyles', ['FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']),
	]

	const bareBonesToolbar: Array<ToolbarItem | string> = [
		formToolbarItem('paragraph', ['BulletedList']),
		formToolbarItem('clipboard', ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']),
		formToolbarItem('paragraph', ['BulletedList']),
		formToolbarItem('tools', ['Maximize']),
	]

	// This is for the bulleted only list view
	const emptyToolbar: Array<ToolbarItem | string> = [
		formToolbarItem('paragraph', ['BulletedList']),
		formToolbarItem('clipboard', ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo']),
		formToolbarItem('tools', ['Maximize']),
	]

	const fileTools_requestHeaders: any = {}
	if (props.entityIdForFileUpload) {
		const sessionToken = user?.access_token
		if (sessionToken) {
			fileTools_requestHeaders.Authorization = `Bearer ${sessionToken}`
		}
	}

	const filebrowserUploadUrl = props.entityIdForFileUpload
		? urlBuilder.setEndpoint(`Assets/CKEditorUpload/${props.entityIdForFileUpload}`).url()
		: null

	// Determines which toolbar to display
	const toolbarContent = () => {
		return match({ isBulletedOnly: props.isBulletedOnly, isTextOnly: props.isTextOnly, isBareBones: props.bareBones })
			.with({ isBulletedOnly: true }, () => emptyToolbar)
			.with({ isTextOnly: true }, () => textOnlyToolbar)
			.with({ isBareBones: true }, () => bareBonesToolbar)
			.otherwise(() => toolbar)
	}

	return (
		<div className={cx(`form-textfield ${props.className ?? ''}`, styles['container'])} onBlur={props.onBlur}>
			<Text variant={props.useSmallTextLabel ? 'body2' : undefined}>
				{props.label}
				{props.required ? '*' : ''}{' '}
			</Text>
			<CKEditor
				initData={props.value}
				config={{
					allowedContent: true,
					embed_provider: embedProvider,
					extraPlugins: 'embed, autoembed, image, font, indent, justify, indentlist, indentblock, colorbutton',
					fileTools_requestHeaders,
					filebrowserUploadUrl: filebrowserUploadUrl,
					isReadOnly: props.readonly,
					removeButtons: '',
					toolbar: toolbarContent(),
				}}
				onChange={props.onChange}
				onBlur={onEditorChange}
				onPaste={onPaste}
				data={props.value}
				readOnly={props.readonly}
				type='classic'
			/>
			<div className={`error ${props.hasError ? 'error-show' : 'error-hide'} error-message`}>{props.errorMessage}</div>
		</div>
	)
}

export default FormRichText
