import { Link } from '@mui/material'
import { UIMatch, generatePath } from 'react-router-dom'
import { DynamicBreadcrumb } from './breadcrumbs'
import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useDiscountOfferRoutes = () => {
	// const flags  = useFlags()
	const routes = prepareRoutes(
		[
			{
				path: routePaths.discounters.path,

				handle: {
					crumb: () => <Link href={routePaths.discounters.path}>Discounts</Link>,
				},
				children: [
					{
						index: true,
						lazy: () => import('pages/discounters').then((module) => ({ Component: module.default })),
					},
					...prepareRoutes(
						[
							{
								lazy: () => import('pages/create-discounter').then((module) => ({ Component: module.default })),
								path: routePaths.discounters.create.path,
							},
							{
								path: routePaths.discounters.edit.path,
								handle: {
									crumb: (data: UIMatch<any, any>) => {
										return <DynamicBreadcrumb portalId={data.params.discounterId ?? ''} />
									},
								},
								lazy: () =>
									import('pages/discounters/edit/layout').then((module) => ({
										Component: module.DiscountersEditLayout,
									})),
								children: [
									{
										index: true,
										lazy: () => import('pages/edit-discounter').then((module) => ({ Component: module.default })),
									},
									...prepareRoutes(
										[
											{
												path: routePaths.discounters.edit.offers.list.path,
												handle: {
													crumb: (data: UIMatch<any, any>) => (
														<Link
															href={generatePath(routePaths.discounters.edit.offers.list.path, {
																discounterId: data.params.discounterId!,
															})}
														>
															Offers
														</Link>
													),
												},
												children: [
													{
														index: true,
														lazy: () =>
															import('pages/discount-offers').then((module) => ({ Component: module.default })),
													},
													...prepareRoutes(
														[
															{
																lazy: () =>
																	import('pages/create-discount-offer').then((module) => ({
																		Component: module.default,
																	})),
																path: routePaths.discounters.edit.offers.create.path,
																handle: {
																	crumb: () => <div>Create Offer</div>,
																},
															},
															{
																lazy: () =>
																	import('pages/edit-discount-offer').then((module) => ({ Component: module.default })),
																path: routePaths.discounters.edit.offers.edit.path,
																handle: {
																	crumb: (data: UIMatch<any, any>) => {
																		return <DynamicBreadcrumb portalId={data.params.discountOfferId ?? ''} />
																	},
																},
															},
														],
														{
															stripLeading: `${routePaths.discounters.edit.offers.list.path}/`,
														},
													),
												],
											},
										],
										{ stripLeading: `${routePaths.discounters.edit.path}/` },
									),
								],
							},
						],
						{ stripLeading: `${routePaths.discounters.path}/` },
					),
				],
			},
		],
		{},
	)

	return routes
}
