import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Schema, ValidationError } from 'yup'

import { KeyValue } from 'types/key-value'

export type UseFormReturnType<T> = Readonly<{
	clearFieldErrors: (field: string) => void
	clearNestedErrors: (index: number) => void
	errors: KeyValue | null
	handleSubmit: (...args: any[]) => any
	isDirty: boolean
	isSubmitting: boolean
	setFields: any
	setIsSubmitting: Dispatch<SetStateAction<boolean>>
	setVals: Dispatch<SetStateAction<T>>
	shouldDisableSubmit: boolean
	touchedFields: string[]
	validate: (field: string) => void
	validateAllFields: (index?: number) => Promise<boolean | undefined>
	values: T
	reset: () => void
}>

/**
 * useForm hook with state management and form validation
 * @param initialState - Initial state for the form
 * @param onSubmit - onSubmit composed function.
 * 	Does validation and passes the values to the onSubmit and useAsyncFetch hook
 * @param validatorSchema - Yup schema for validation
 */
export function useForm<T, S = Record<string, unknown>>(
	initialState: T,
	onSubmit: (...args: any[]) => any,
	validatorSchema?: Schema<S>,
): UseFormReturnType<T> {
	const [touchedFields, setTouchedFields] = useState<string[]>([])
	const [values, setVals] = useState(initialState)
	const [isDirty, setIsDirty] = useState(false)
	const [errors, setErrors] = useState<KeyValue | null>(null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [shouldDisableSubmit, setDisableSubmit] = useState(true)

	useEffect(() => {
		const shouldDisableSubmit = !validatorSchema?.isValidSync(values) || !isDirty || isSubmitting
		setDisableSubmit(shouldDisableSubmit)
	}, [isDirty, isSubmitting, validatorSchema, values])

	const clearFormFieldEvents = () => {
		setIsDirty(false)
		setTouchedFields([])
		setErrors(null)
	}

	// Create memoized version to avoid useless re-renders
	// Adding values to setFields in order to do batch updates at once
	const setFields = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, index?: number, vals?: T) => {
			const isCheckbox = e?.target?.type?.toLowerCase() === 'checkbox'
			if (e.persist) {
				e.persist()
			}
			if (e.preventDefault && !isCheckbox) {
				e.preventDefault()
			}
			if (!isDirty) {
				setIsDirty(true)
			}

			setTouchedFields((prevTouchedFields) =>
				prevTouchedFields.find((val) => val === e?.target?.name)
					? prevTouchedFields
					: [...prevTouchedFields, e?.target?.name],
			)

			setVals((prevFields: any) => {
				if (vals && Array.isArray(vals)) {
					return [...vals]
				} else if (vals && typeof vals === 'object') {
					return { ...prevFields, vals }
				}

				if (Array.isArray(prevFields) && index !== undefined) {
					const newState = [...prevFields]
					const value = isCheckbox ? !prevFields[index][e.target.name] : e.target.value
					newState[index] = { ...newState[index], [e.target.name]: value }

					return newState
				}

				const value = isCheckbox ? !prevFields[e.target.name] : e.target.value

				return { ...prevFields, [e.target.name]: value }
			})
		},
		[isDirty],
	)

	const getErrors = (nestedErrors: ValidationError[]): Record<string, string> => {
		const errors = {}

		nestedErrors?.forEach((err: ValidationError): void => {
			if (errors[err.path]) {
				errors[err.path].push(err.message)
			} else {
				errors[err.path] = [err.message]
			}
		})

		return errors
	}

	async function validate(field: string, index?: number): Promise<void> {
		if (!validatorSchema) return

		try {
			const value = (index != null ? values[index] : values) as unknown as S
			await validatorSchema.validateAt(field, value, { abortEarly: false })
			setErrors((prevErrors) => {
				const updatedErrors = { ...prevErrors }
				if (index != null) {
					if (updatedErrors[index]) {
						updatedErrors[index][field] = null
					}
				} else {
					updatedErrors[field] = null
				}

				return updatedErrors
			})
		} catch (error) {
			const { inner } = error as { inner: ValidationError[] }

			setErrors((prevErrors) => {
				const errors = { ...getErrors(inner as ValidationError[]) }

				const newErrors = index != null ? { [index]: { ...(prevErrors?.[index] ?? {}), ...errors } } : { ...errors }

				return { ...prevErrors, ...newErrors }
			})
		}
	}

	async function validateAllFields(index?: number): Promise<boolean | undefined> {
		if (!validatorSchema) return

		const vals = index != null ? values[index] : values

		return validatorSchema
			.validate(vals, { abortEarly: false })
			.then(() => {
				clearFormFieldEvents()

				return true
			})
			.catch(({ inner }: ValidationError) => {
				setErrors((prevErrors) => {
					const errors = { ...getErrors(inner as ValidationError[]) }
					const newErrors = index != null ? { [index]: { ...(prevErrors?.[index] ?? {}), ...errors } } : { ...errors }

					return { ...prevErrors, ...newErrors }
				})

				return false
			})
	}

	function clearNestedErrors(index: number) {
		if (errors && errors[index]) {
			setErrors((prevErrors) => {
				const updatedErrors = { ...prevErrors }
				updatedErrors[index] = null

				return updatedErrors
			})
		}
	}

	const clearFieldErrors = (field: string, index?: number): void => {
		if (index != null && errors?.[index]) {
			setErrors((prevErrors) => {
				const updatedErrors = { ...prevErrors }
				updatedErrors[index][field] = null

				return updatedErrors
			})
		} else {
			setErrors((prevErrors) => {
				const updatedErrors = { ...prevErrors }
				updatedErrors[field] = null

				return updatedErrors
			})
		}
	}

	const handleSubmit = useCallback(
		(...args: any[]): void => {
			setIsSubmitting(true)

			if (validatorSchema) {
				validatorSchema
					.validate(values, { abortEarly: false })
					.then((values) => {
						onSubmit(values, ...args)
						clearFormFieldEvents()
					})
					.catch(({ inner }: ValidationError) => {
						setIsSubmitting(false)
						setErrors(getErrors(inner))
					})
			} else {
				onSubmit(values, ...args)
				clearFormFieldEvents()
			}
		},
		[onSubmit, validatorSchema, values],
	)

	const reset = (): void => {
		setVals(initialState)
		setIsDirty(false)
		setErrors(null)
		setIsSubmitting(false)
		setDisableSubmit(true)
		setTouchedFields([])
	}

	return {
		clearFieldErrors,
		clearNestedErrors,
		errors,
		handleSubmit,
		isDirty,
		isSubmitting,
		reset,
		setFields,
		setIsSubmitting,
		setVals,
		shouldDisableSubmit,
		touchedFields,
		validate,
		validateAllFields,
		values,
	}
}
