import { CssVarsThemeOptions } from '@mui/material'

export function container(): CssVarsThemeOptions['components'] {
	return {
		MuiContainer: {
			styleOverrides: {
				root: ({ theme }) => {
					return {
						backgroundColor: theme.vars.palette.background.default,
						paddingTop: theme.spacing(2),
						paddingBottom: theme.spacing(2),
					}
				},
			},
		},
	}
}
