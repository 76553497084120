import { Grid } from '@material-ui/core'
import { Button } from 'components/buttons'
import Text from 'components/text'
import { Link } from 'react-router-dom'
import { BenefitPlanV2 } from 'types/tenants/benefit-plan-v2'

interface RatesFormContainerProps {
	isSubmitting: boolean
	handleSubmit: () => void
	benefitPlan?: BenefitPlanV2 | null
	orgId: string
}

const RatesFormHeader = ({ isSubmitting, handleSubmit, benefitPlan, orgId }: RatesFormContainerProps) => {
	const ratesBaseUrl = `/organizations/edit/${orgId}/benefit-plans/edit/${benefitPlan?.benefitPlanId}/rates`
	const goBackTo = ratesBaseUrl

	return (
		<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
			<Grid item xs={6}>
				<Text>{benefitPlan?.benefitPlanName} - Plan Highlights</Text>
			</Grid>

			<Grid
				className='save-button-container'
				container
				item
				xs={6}
				justifyContent='flex-end'
				alignItems='center'
				spacing={3}
			>
				<Grid item>
					<Link className='back-button' to={goBackTo}>
						Back to List
					</Link>
				</Grid>

				<Grid item>
					<Button
						className='submit-button'
						color='secondary'
						id='submit-button'
						isLoading={isSubmitting}
						onClick={handleSubmit}
					>
						Save
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default RatesFormHeader
