import { mutationKeys } from 'api/benefit-elections/keys'
import { RatesService } from 'api/benefit-elections/services/rates-service'
import { AxiosError } from 'axios'
import { RateFormTypeForPost } from 'components/rates-form/types'
import { useMutation } from 'react-query'
import { Rates } from 'types/tenants/rates'

export interface EditRateDTO {
	benefitPlanId: string
	updatedValues: RateFormTypeForPost
}

export const useEditRates = () =>
	useMutation<Rates, AxiosError<any>, EditRateDTO>(
		(payload: EditRateDTO) => RatesService.editRate(payload.benefitPlanId, payload.updatedValues),
		{
			mutationKey: mutationKeys.benefitPlans.rates.edit(),
		},
	)
