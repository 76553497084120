import { AsyncProviderConfig, asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { StrictMode } from 'react'
import UrlBuilder from 'services/util/url-builder'
import { Environments } from 'utils/env'
import App from './app'
import { SiteEnvironment } from './types'

export async function setupApp(site: SiteEnvironment, root) {
	new UrlBuilder().setHost(site.env)

	const LDProvider = await asyncWithLDProvider(configureLaunchDarkly(site.env as Environments))

	root.render(
		<StrictMode>
			<LDProvider>
				<App />
			</LDProvider>
		</StrictMode>,
	)
}

/**
 * Setup the LaunchDarkly contexts for the user and organization
 */
function configureLaunchDarkly(env: Environments): AsyncProviderConfig {
	const ldClientId = getLdClientIdForEnv(env)

	return {
		clientSideID: ldClientId,
		context: {
			kind: 'multi',
			organization: {
				key: 'ADMIN',
				name: 'ADMIN',
			},
			user: {
				key: 'ADMIN',
				organization: 'ADMIN',
			},
		},
		options: { eventCapacity: 500 },
		reactOptions: { useCamelCaseFlagKeys: false },
	}
}

/**
 * From the environment, get the correct LD client id configured in the .env files
 */
function getLdClientIdForEnv(env: Environments) {
	const ldClientIds = {
		[Environments.DEV]: import.meta.env.VITE_LD_ID_DEV,
		[Environments.INT]: import.meta.env.VITE_LD_ID_INT,
		[Environments.UAT]: import.meta.env.VITE_LD_ID_UAT,
	}

	const ldClientId = ldClientIds[env] || import.meta.env.VITE_LD_ID

	return ldClientId
}
