import {
	CreateReconciliationConfigurationRequest,
	EditReconciliationConfigurationRequest,
	GetInvoicesRequest,
	GetInvoicesResponse,
	InvoiceDiscrepanciesRequest,
	InvoiceDiscrepanciesResponse,
	InvoiceReconciliationConfigResponse,
	InvoiceReconciliationDetailsResponse,
} from 'types/invoices'
import {
	INVOICES,
	ORGANIZATIONS,
	RECONCILIATION_CONFIGURATIONS,
} from '../api/billing-file-processor/services/constants'

import UrlBuilder from './util/url-builder'
import axios from 'config'
import { v4 } from 'uuid'

const DISCREPANCIES = 'discrepancies'

const getAllUrlBuilder: UrlBuilder = new UrlBuilder('billingapi')

class InvoicesService {
	static getInvoices({
		endDate,
		order,
		orderBy,
		organizationId,
		rowsPerPage,
		status,
		page,
		startDate,
	}: GetInvoicesRequest): Promise<GetInvoicesResponse> {
		const url: string = getAllUrlBuilder
			.setEndpoint(INVOICES)
			.setQueryParams(
				new URLSearchParams({
					...(endDate ? { endDate } : {}),
					...(orderBy ? { sortBy: `${orderBy}:${order}` } : {}),
					...(organizationId ? { organizationId } : {}),
					...(status ? { status } : {}),
					...(page ? { page } : {}),
					...(rowsPerPage ? { pageSize: rowsPerPage } : {}),
					...(startDate ? { startDate } : {}),
				}),
			)
			.url()

		return axios.get(url)
	}

	static createReconciliationConfiguration(data: CreateReconciliationConfigurationRequest): Promise<void> {
		const url: string = getAllUrlBuilder
			.setEndpoint(`${ORGANIZATIONS}/${data.organizationId}/${RECONCILIATION_CONFIGURATIONS}`)
			.url()

		return axios.post(url, data)
	}

	static editReconciliationConfiguration(data: EditReconciliationConfigurationRequest): Promise<void> {
		const url: string = getAllUrlBuilder
			.setEndpoint(`${ORGANIZATIONS}/${data.organizationId}/${RECONCILIATION_CONFIGURATIONS}/${data.id}`)
			.url()

		return axios.put(url, data)
	}

	static getReconciliationConfiguration(organizationId: string): Promise<InvoiceReconciliationConfigResponse> {
		const url: string = getAllUrlBuilder
			.setEndpoint(`${ORGANIZATIONS}/${organizationId}/${RECONCILIATION_CONFIGURATIONS}`)
			.url()

		return axios.get(url)
	}

	static getInvoiceById({ invoiceId }: { invoiceId: string }): Promise<InvoiceReconciliationDetailsResponse> {
		const url: string = getAllUrlBuilder.setEndpoint(`${INVOICES}/${invoiceId}`).url()

		return axios.get(url)
	}

	static updateStatusToReadyForProviderPayment({ invoiceId }: { invoiceId: string }): Promise<void> {
		const url: string = getAllUrlBuilder
			.setEndpoint(`${INVOICES}/${invoiceId}/changeStatus?newStatus=ReadyForProviderPayment`)
			.url()

		return axios.post(url)
	}

	static updateStatusToProviderPaymentSubmitted({ invoiceId }: { invoiceId: string }): Promise<void> {
		const url: string = getAllUrlBuilder
			.setEndpoint(`${INVOICES}/${invoiceId}/changeStatus?newStatus=ProviderPaymentSubmitted`)
			.url()

		return axios.post(url)
	}

	static updateStatusToOrganizationPaymentReconciled({ invoiceId }: { invoiceId: string }): Promise<void> {
		const url: string = getAllUrlBuilder
			.setEndpoint(`${INVOICES}/${invoiceId}/changeStatus?newStatus=OrganizationPaymentReconciled`)
			.url()

		return axios.post(url)
	}

	static async getDiscrepancies({
		invoiceId,
		queryParams,
	}: InvoiceDiscrepanciesRequest): Promise<InvoiceDiscrepanciesResponse> {
		const { order, orderBy, page, rowsPerPage } = queryParams
		const url: string = getAllUrlBuilder
			.setEndpoint(`${INVOICES}/${invoiceId}/${DISCREPANCIES}`)
			.setQueryParams(
				new URLSearchParams({
					...(orderBy ? { sortBy: `${orderBy}:${order}` } : {}),
					...(page ? { page } : {}),
					...(rowsPerPage ? { pageSize: rowsPerPage } : {}),
				}),
			)
			.url()

		const results: InvoiceDiscrepanciesResponse = await axios.get(url)

		const mappedResults = {
			currentResultCount: results.currentResultCount,
			invoiceNumber: results.invoiceNumber,
			items: results.items.map((item) => {
				return {
					...item,
					id: v4(),
				}
			}),
			page: results.page,
			totalResults: results.totalResults,
		}

		return mappedResults
	}
}

export default InvoicesService
