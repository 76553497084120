import { ThemeProvider, createTheme } from '@material-ui/core'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SuccessSnackbar } from 'components/snackbar'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Maintenance from 'pages/maintenance'
import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import Routes from 'routes/routes'
import store from 'store'
import { ThemeProvider as CsMuiThemeProvider } from 'theme'
import { runCacheBuster } from 'utils/run-cache-buster'

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

export default function AppMaintenanceDeterminer(): JSX.Element {
	const { showAdminMaintenancePage } = useFlags()
	if (showAdminMaintenancePage) {
		return <Maintenance />
	}

	return <App />
}

const App: React.FC<Record<string, never>> = () => {
	useEffect(() => {
		runCacheBuster()
	}, [])

	const reactPlugin = new ReactPlugin()
	const theme = createTheme({ palette: { primary: { main: '#2b2c2e' }, secondary: { main: '#ffc213' } } })

	// Need to have this in place in production apps. Should also
	if (import.meta.env.MODE === 'production') {
		const appInsights = new ApplicationInsights({
			config: {
				extensions: [reactPlugin as unknown as ITelemetryPlugin],
				instrumentationKey: import.meta.env.VITE_APPLICATION_INSIGHTS_KEY,
			},
		})
		appInsights.loadAppInsights()
	}

	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<CsMuiThemeProvider>
						<ThemeProvider theme={theme}>
							<SuccessSnackbar />
							<Routes />
						</ThemeProvider>
					</CsMuiThemeProvider>
				</LocalizationProvider>
			</Provider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	)
}
