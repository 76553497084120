import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useEmployeeProfileRoutes = () => {
	// const flags = useFlags()

	const routes = prepareRoutes(
		[
			{
				lazy: () => import('pages/employee-profile').then((module) => ({ Component: module.default })),
				path: routePaths.employeeSearch.tenant.profile.details.path,
			},
			{
				lazy: () =>
					import('pages/employee-enrollments/employee-enrollments').then((module) => ({ Component: module.default })),
				path: routePaths.employeeSearch.tenant.profile.enrollments.path,
			},
			{
				lazy: () => import('pages/census-history').then((module) => ({ Component: module.default })),
				path: routePaths.employeeSearch.tenant.profile.censusHistory.path,
			},
			{
				lazy: () => import('pages/employee-file-history').then((module) => ({ Component: module.default })),
				path: routePaths.employeeSearch.tenant.profile.employeeFileHistory.path,
			},
		],
		{
			stripLeading: `${routePaths.employeeSearch.tenant.profile.path}/`,
		},
	)

	return routes
}
