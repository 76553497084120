import EditRates from 'pages/edit-rates'
import { Navigate } from 'react-router-dom'
import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useOrgBenefitPlanEditRateNestedRoutes = () => {
	// const flags = useFlags()
	const routes = prepareRoutes(
		[
			{
				index: true,
				path: routePaths.organizations.edit.benefitPlans.rates.edit.details.path,
				element: <EditRates />,
			},
			{
				path: routePaths.organizations.edit.benefitPlans.rates.edit.highlights.path,
				element: <EditRates />,
			},
			{
				path: '*',
				element: <Navigate to='details' replace />,
			},
		],
		{
			stripLeading: `${routePaths.organizations.edit.benefitPlans.rates.edit.path}/`,
		},
	)

	return routes
}
