import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { UseCrumbRegistrationReturn, useCrumbStore } from '../state'

export const useBreadcrumb = (id?: string): UseCrumbRegistrationReturn => {
	const crumbElement = useCrumbElement(id)
	const content = useRef<ReactNode | null>(null)
	const hasUpdated = useRef(false)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, forceUpdate] = useState(0)

	const updateCrumbValue = useCallback(
		(crumbValue: ReactNode, retryCount = 0) => {
			if (crumbElement?.current) {
				content.current = createPortal(crumbValue, crumbElement.current)
				hasUpdated.current = true
				forceUpdate((n) => n + 1)
			} else {
				console.warn('No crumb element found for', id, 'retrying', retryCount + 1)
				if (retryCount < 3) {
					setTimeout(() => updateCrumbValue(crumbValue, retryCount + 1), 1000)
				}
			}
		},
		[crumbElement, id],
	)

	useEffect(() => {
		if (crumbElement?.current && !hasUpdated.current) {
			updateCrumbValue(content.current)
		}
	}, [crumbElement, id, updateCrumbValue])

	return { updateCrumbValue, CrumbPortalRef: content }
}

const useCrumbElement = (id?: string) => {
	const crumbRef = useCrumbStore((s) => (id ? s.crumbs[id] : null))

	return crumbRef
}
