import { UseQueryResult, useQuery } from 'react-query'
import { BenefitPlanV2 } from 'types/tenants/benefit-plan-v2'
import { queryKeys } from '../keys'
import BenefitPlansService from '../services/benefit-plans'

export const useGetBenefitPlanById: (props: {
	benefitPlanId: string
	onSuccess?: (benefitPlan: BenefitPlanV2) => void
}) => UseQueryResult<BenefitPlanV2> = ({ benefitPlanId, onSuccess }) =>
	useQuery(queryKeys.benefitPlans.getById(benefitPlanId), () => BenefitPlansService.getBenefitPlanById(benefitPlanId), {
		onSuccess,
	})
