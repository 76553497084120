import { Grid } from '@material-ui/core'
import { useEditRates } from 'api/benefit-elections/mutations/rates'
import { useGetRates } from 'api/benefit-elections/queries/use-get-latest-rates'
import Loading from 'components/loading'
import { RatesForm, RatesHighlightsForm } from 'components/rates-form'
import { RatesEdit } from 'components/rates-form/rates-edit.class'
import { formatFormValues } from 'components/rates-form/rates-form-utils'
import { FormPlanTiers, RatesFormType, initialState, validationSchema } from 'components/rates-form/types'
import { useForm } from 'hooks/use-form'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { renderSnackbar, updateIsSubmitting } from 'utils'

const EditRates = () => {
	const { benefitPlanId, rateInfoId } = useParams<{ benefitPlanId: string; rateInfoId: string }>()
	const [isDataUpdating, setIsUpdating] = useState<boolean>(true)
	const [successMessage, setSuccessMessage] = useState<string>('')

	const {
		data: rate,
		error: submitError,
		mutateAsync: submitForm,
		status: reqStatus,
		isError: isSubmitError,
	} = useEditRates()

	const {
		data: editRate,
		isError: isEditRateError,
		status: fetchRateStatus,
	} = useGetRates({
		benefitPlanId: benefitPlanId!,
		rateInfoId: rateInfoId!,
	})

	useEffect(() => {
		function clearSuccessMessageIfError(
			successMessage: string | null,
			editRateError: boolean,
			submitError: boolean,
		): void {
			if (successMessage && (editRateError || submitError)) {
				setSuccessMessage('')
			}
		}

		clearSuccessMessageIfError(successMessage, isEditRateError, isSubmitError)
	}, [isEditRateError, isSubmitError, successMessage])

	const onSubmit = (): void => {
		const { planCoverages } = form.values
		const planTiers: FormPlanTiers[] = []
		planCoverages.forEach((planCoverage) => {
			planCoverage.planTierRates.forEach((planTierRate) => {
				planTiers.push({
					ageFrom: planCoverage.ageFrom,
					ageTo: planCoverage.ageTo,
					amount: planTierRate.amount,
					planCoverageContent: planCoverage.planCoverageContent,
					planCoverageId: planCoverage.planCoverageId,
					planRateId: planTierRate.planRateId,
					planTierId: planTierRate.planTierId,
					smoker: planCoverage.smoker,
				})
			})
		})

		setIsUpdating(true)

		const updatedValues = formatFormValues(form.values, planTiers)

		submitForm({ benefitPlanId: benefitPlanId!, updatedValues })
	}
	const form = useForm<RatesFormType>(initialState, onSubmit, validationSchema)
	const { setVals } = form

	useEffect(() => {
		const updateSuccessStates = (): void => {
			setSuccessMessage(`Rates were modified successfully.`)
		}
		if (reqStatus === 'success') {
			updateSuccessStates()
		}
	}, [rate, reqStatus])

	useEffect(() => {
		updateIsSubmitting(reqStatus, form.setIsSubmitting)
	}, [reqStatus, form.setIsSubmitting])

	useEffect(() => {
		if (fetchRateStatus === 'success' && editRate) {
			setVals(new RatesEdit({ ...initialState, ...editRate }))
			setIsUpdating(false)
		}
	}, [editRate, fetchRateStatus, setVals])

	useEffect(() => {
		if (reqStatus === 'success' && rate) {
			setVals(new RatesEdit({ ...initialState, ...rate }))
			setIsUpdating(false)
		} else if (reqStatus === 'error') {
			setIsUpdating(false)
		}
	}, [rate, reqStatus, setVals])

	const renderRateForm = () => {
		const highlights = location.pathname.includes('highlights')

		return highlights ? <RatesHighlightsForm form={form} /> : <RatesForm isEditRate form={form} />
	}

	return (
		<Grid className='create-grid' xs={12} item>
			{renderSnackbar(reqStatus, successMessage, submitError?.response?.data?.detail ?? '')}
			{isDataUpdating || fetchRateStatus === 'loading' ? <Loading /> : renderRateForm()}
		</Grid>
	)
}

export default EditRates
