/* eslint-disable sort-keys */
import { Environments } from 'utils/env'
import { SiteEnvironment } from './types'

const options = [
	{ value: 'int', label: 'Int' },
	{ value: 'dev', label: 'Dev' },
	// { value: 'test', label: 'UAT' },
	// { value: '', label: 'Prod' },
]

export function PreviewEnvironmentSelectorForm({ onSubmit }: { onSubmit: (site: SiteEnvironment) => void }) {
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()

				const data = new FormData(e.target as HTMLFormElement)

				const object: {
					env: string
					tenant: string
				} = {
					env: '',
					tenant: '',
				}
				data.forEach((value, key) => (object[key] = value))

				onSubmit({
					env: object.env as Environments,
				})
			}}
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
				gap: '1rem',
			}}
		>
			<div style={{ display: 'flex', gap: '1rem' }}>
				<label htmlFor='env'>Environment</label>
				<select name='env' id='env'>
					{options.map((v) => (
						<option key={v.value} value={v.value}>
							{v.label}
						</option>
					))}
				</select>
			</div>
			<button type='submit'>Submit</button>
		</form>
	)
}
