import { createRatesFormData, formHeaders } from 'components/form/util'
import axios from 'config'
import UrlBuilder from 'services/util/url-builder'
import { PagedResponse } from 'types'
import { BenefitPlanV2 } from 'types/tenants/benefit-plan-v2'
import { Rates } from 'types/tenants/rates'
import { API_NAME, endpoints } from '../constants'
import { BenefitPlanOptions } from '../types/benefit-plan-options'
import { BenefitPlanSearchParameters } from '../types/benefit-plan-search'

const urlBuilder: UrlBuilder = new UrlBuilder(API_NAME)

const BENEFIT_PLANS = 'BenefitPlans'

export default class BenefitPlansService {
	static async getDropdownOptions(organizationId: string, providerId: string): Promise<BenefitPlanOptions[]> {
		const query = new URLSearchParams()
		query.append('benefitProviderId', providerId)
		query.append('organizationId', organizationId)
		query.append('status', 'Active')
		query.append('status', 'Grandfathered')

		// prettier-ignore
		const url: string = urlBuilder
			.setEndpoint(`${BENEFIT_PLANS}/dropdown-options`)
			.setQueryParams(query)
			.url()

		return axios.get(`${url}`)
	}

	static async getBenefitPlans(seachParams?: BenefitPlanSearchParameters): Promise<PagedResponse<BenefitPlanV2>> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`${BENEFIT_PLANS}`)

		if (seachParams) {
			endpoint.setQueryParams(new URLSearchParams(seachParams))
		}

		return axios.get(endpoint.url())
	}

	static async getBenefitPlanById(benefitPlanId: string): Promise<BenefitPlanV2> {
		const url: string = urlBuilder.setEndpoint(endpoints.BenefitPlans.getById(benefitPlanId)).url()

		return axios.get(url)
	}

	static editBenefitPlanRate(id: string, data: any): Promise<Rates> {
		const url: string = urlBuilder.setEndpoint(endpoints.BenefitPlans.editRates(id, data.rateInfoId)).url()

		return axios.put(url, createRatesFormData(data), formHeaders)
	}
}
