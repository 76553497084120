import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useProductionSupportRoutes = () => {
	return prepareRoutes(
		[
			{
				lazy: () => import('pages/production-support-dashboard').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.root,
			},
			{
				lazy: () => import('pages/deduction-requests-bulk-update').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.deductionRequest.bulk.path,
			},
			{
				lazy: () =>
					import('pages/edit-deduction-requests-bulk-update').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.deductionRequest.bulk.edit.path,
			},
			{
				lazy: () => import('pages/employee-ledger').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.enrollmentLedger.path,
			},
			{
				lazy: () => import('pages/ppds-dashboard').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.deductionRequest.path,
			},
			{
				lazy: () => import('pages/ppds-dashboard-details').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.deductionRequest.details.path,
			},
			{
				lazy: () => import('pages/enrollment-search').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.enrollmentSearch.path,
			},
			{
				lazy: () => import('pages/enrollment-details').then((module) => ({ Component: module.default })),
				path: routePaths.productionSupport.enrollmentSearch.details.path,
			},
		],
		{},
	)
}
