/* eslint-disable sort-keys */
import { buttonClasses } from '@mui/material/Button'
import { CssVarsThemeOptions, alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

// NEW VARIANT
declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		soft: true
	}
}

// ----------------------------------------------------------------------

export function button(): CssVarsThemeOptions['components'] {
	return {
		MuiButton: {
			styleOverrides: {
				root: ({ theme, ownerState }) => {
					const inheritColor = ownerState.color === 'inherit'

					const containedVariant = ownerState.variant === 'contained'
					const outlinedVariant = ownerState.variant === 'outlined'
					const textVariant = ownerState.variant === 'text'
					const softVariant = ownerState.variant === 'soft'

					const smallSize = ownerState.size === 'small'
					const mediumSize = ownerState.size === 'medium'
					const largeSize = ownerState.size === 'large'

					const defaultStyle = {
						...(inheritColor && {
							// CONTAINED
							...(containedVariant && {
								color: theme.palette.common.white,
								backgroundColor: theme.palette.grey[800],
								'&:hover': {
									backgroundColor: theme.palette.grey[700],
								},
							}),
							// OUTLINED
							...(outlinedVariant && {
								borderColor: theme.palette.common.black,
								'&:hover': {
									backgroundColor: theme.vars.palette.action.hover,
								},
							}),
							// TEXT
							...(textVariant && {
								'&:hover': {
									backgroundColor: theme.palette.action.hover,
								},
							}),
							// SOFT
							...(softVariant && {
								color: theme.palette.text.primary,
								backgroundColor: alpha(theme.palette.grey[500], 0.08),
								'&:hover': {
									backgroundColor: alpha(theme.palette.grey[500], 0.24),
								},
							}),
						}),
						...(outlinedVariant && {
							'&:hover': {
								borderColor: 'currentColor',
								boxShadow: '0 0 0 1px currentColor',
							},
						}),
					}

					const colorStyle = COLORS.map((color) => ({
						...(ownerState.color === color && {
							// CONTAINED
							...(containedVariant && {
								'&:hover': {
									boxShadow: theme.customShadows[color],
								},
							}),
							// SOFT
							...(softVariant && {
								color: theme.vars.palette[color]['light'],
								backgroundColor: `rgba(${theme.vars.palette[color].mainChannel} / 0.16)`,
								'&:hover': {
									backgroundColor: `rgba(${theme.palette[color].mainChannel} / 0.32)`,
								},
							}),
						}),
					}))

					const disabledState = {
						[`&.${buttonClasses.disabled}`]: {
							// SOFT
							...(softVariant && {
								backgroundColor: theme.vars.palette.action.disabledBackground,
							}),
						},
					}

					const size = {
						...(smallSize && {
							height: 30,
							fontSize: 13,
							paddingLeft: 8,
							paddingRight: 8,
							...(textVariant && {
								paddingLeft: 4,
								paddingRight: 4,
							}),
						}),
						...(mediumSize && {
							paddingTop: 'var(--space-m)',
							paddingBottom: 'var(--space-m)',
							paddingLeft: 'calc(var(--space-l) * 1.5)',
							paddingRight: 'calc(var(--space-l) * 1.5)',
							...(textVariant && {
								paddingLeft: 8,
								paddingRight: 8,
							}),
							borderRadius: '100px',
							fontSize: 'var(--font-size-p)',
							fontWeight: 'var(--font-weight-7)',
						}),
						...(largeSize && {
							height: 48,
							fontSize: 15,
							paddingLeft: 16,
							paddingRight: 16,
							borderRadius: '100px',
							...(textVariant && {
								paddingLeft: 10,
								paddingRight: 10,
							}),
						}),
					}

					return [defaultStyle, ...colorStyle, disabledState, size]
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					'&.MuiButtonBase-root': {
						'&:focus-visible': {
							outline: '2px solid black',
							outlineStyle: 'auto',
						},
					},
				},
			},
		},
	}
}
