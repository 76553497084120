import { SNACKBAR_CLEAR, SNACKBAR_ERROR, SNACKBAR_SUCCESS } from './types'

export const showSuccessSnackbar = (message: string) => {
	return (dispatch: (arg0: { type: string; payload: string }) => void): void => {
		dispatch({ payload: message, type: SNACKBAR_SUCCESS })
	}
}

export const showErrorSnackbar = (message: string) => {
	return (dispatch: (arg0: { type: string; payload: string }) => void): void => {
		dispatch({ payload: message, type: SNACKBAR_ERROR })
	}
}

export const clearSnackbar = () => {
	return (dispatch: (arg0: { type: string }) => void): void => {
		dispatch({ type: SNACKBAR_CLEAR })
	}
}
