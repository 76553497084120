import { Link } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, UIMatch, generatePath } from 'react-router-dom'
import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useFileMonitoringRoutes = () => {
	const { transformers8490, transformers11348, transformers8737 } = useFlags()

	const routes = prepareRoutes(
		[
			// NOTE: put all file-line detail pages above this if using /file-monitoring in the path
			{
				lazy: () => import('pages/file-monitoring').then((module) => ({ Component: module.default })),
				handle: {
					crumb: () => <Link href={routePaths.fileMonitoring.root}>File Monitoring</Link>,
				},
				path: `${routePaths.fileMonitoring.root}/*`,
				children: prepareRoutes(
					[
						{
							index: true,
							element: <Navigate to={routePaths.fileMonitoring.unprocessed.path} replace />,
						},
						{
							path: routePaths.fileMonitoring.unprocessed.path,
							lazy: () =>
								import('pages/file-monitoring/unprocessed-files').then((module) => ({
									Component: module.UnprocessedFiles,
								})),
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.unprocessed.path}>Unprocessed Files</Link>,
							},
						},
						{
							path: routePaths.fileMonitoring.overdue.path,
							lazy: () =>
								import('pages/file-monitoring/overdue-files').then((module) => ({
									Component: module.OverdueFiles,
								})),
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.overdue.path}>Overdue Files</Link>,
							},
						},
						{
							path: `${routePaths.fileMonitoring.inboundCensus.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.inboundCensus.path}>Inbound Census</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () => import('pages/census-summary').then((module) => ({ Component: module.default })),
									},
									{
										lazy: () => import('pages/census-errors').then((module) => ({ Component: module.default })),
										path: routePaths.fileMonitoring.inboundCensus.errors.path,
										handle: {
											crumb: () => (
												<Link href={routePaths.fileMonitoring.inboundCensus.errors.path}>Census Errors</Link>
											),
										},
									},
									{
										lazy: () => import('pages/census-warnings').then((module) => ({ Component: module.default })),
										path: routePaths.fileMonitoring.inboundCensus.warnings.path,
										handle: {
											crumb: () => (
												<Link href={routePaths.fileMonitoring.inboundCensus.warnings.path}>Census Warnings</Link>
											),
										},
									},
									{
										lazy: () => import('pages/census-successes').then((module) => ({ Component: module.default })),
										path: routePaths.fileMonitoring.inboundCensus.success.path,
										handle: {
											crumb: () => (
												<Link href={routePaths.fileMonitoring.inboundCensus.success.path}>Census Successes</Link>
											),
										},
									},
									{
										lazy: () => import('pages/census-missing').then((module) => ({ Component: module.default })),
										path: routePaths.fileMonitoring.inboundCensus.missing.path,
										handle: {
											crumb: () => (
												<Link href={routePaths.fileMonitoring.inboundCensus.missing.path}>
													Census Missing Employees
												</Link>
											),
										},
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.inboundCensus.path}/`,
								},
							),
						},
						{
							path: routePaths.fileMonitoring.inboundElections.path,
							lazy: () =>
								import('pages/inbound-elections-file-summary').then((module) => ({
									Component: module.default,
								})),
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.inboundElections.path}>Inbound Elections</Link>,
							},
						},
						{
							path: `${routePaths.fileMonitoring.outboundElections.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.outboundElections.path}>Outbound Elections</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/outbound-election-file-summary').then((module) => ({ Component: module.default })),
									},
									{
										lazy: () =>
											import('pages/outbound-election-file-line-summary-table').then((module) => ({
												Component: module.default,
											})),
										path: routePaths.fileMonitoring.outboundElections.summary.details.path,
										handle: {
											crumb: () => (
												<Link href={routePaths.fileMonitoring.outboundElections.summary.details.path}>
													Outbound Election File Lines
												</Link>
											),
										},
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.outboundElections.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.outboundCensus.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.outboundCensus.path}>Outbound Census</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/outbound-census-summary').then((module) => ({ Component: module.default })),
									},
									{
										lazy: () =>
											import('pages/outbound-census-errors').then((module) => ({ Component: module.default })),
										path: routePaths.fileMonitoring.outboundCensus.errors.path,
										handle: {
											crumb: () => (
												<Link href={routePaths.fileMonitoring.outboundCensus.errors.path}>Outbound Census Errors</Link>
											),
										},
									},
									{
										lazy: () =>
											import('pages/outbound-census-successes').then((module) => ({ Component: module.default })),
										path: routePaths.fileMonitoring.outboundCensus.success.path,
										handle: {
											crumb: () => (
												<Link href={routePaths.fileMonitoring.outboundCensus.success.path}>
													Outbound Census Successes
												</Link>
											),
										},
									},
								],
								{ stripLeading: `${routePaths.fileMonitoring.outboundCensus.path}/` },
							),
						},
						{
							path: `${routePaths.fileMonitoring.providerBills.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.providerBills.path}>Provider Bills</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/provider-bill-file-summary').then((module) => ({ Component: module.default })),
									},
									{
										path: routePaths.fileMonitoring.providerBills.summary.path,
										flag: transformers8737,
										lazy: () =>
											import('pages/provider-bill-file-lines').then((module) => ({
												Component: module.default,
											})),
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.providerBills.summary.path, {
														fileId: data.params.fileId ?? '',
														status: data.params.status ?? '',
													})}
												>
													Provider Bill File Lines
												</Link>
											),
										},
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.providerBills.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.payrollDeduction.path}/*`,
							flag: transformers8490,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.payrollDeduction.path}>Payroll Deduction</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/payroll-deduction-summary/payroll-deduction-summary-page').then((module) => ({
												Component: module.default,
											})),
									},
									{
										lazy: () =>
											import('pages/payroll-deduction-summary/payroll-deduction-detail-lines').then((module) => ({
												Component: module.default,
											})),
										path: routePaths.fileMonitoring.payrollDeduction.fileLineDetails.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.payrollDeduction.fileLineDetails.path, {
														fileId: data.params.fileId ?? '',
													})}
												>
													Payroll Deduction File Details
												</Link>
											),
										},
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.payrollDeduction.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.payrollConfirmation.path}/*`,
							flag: transformers11348,
							handle: {
								crumb: () => (
									<Link href={routePaths.fileMonitoring.payrollConfirmation.path}>Payroll Confirmation</Link>
								),
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/payroll-confirmation-summary/payroll-confirmation-summary-page').then((module) => ({
												Component: module.default,
											})),
									},
									{
										lazy: () =>
											import('pages/payroll-confirmation-summary/payroll-confirmation-detail-lines').then((module) => ({
												Component: module.default,
											})),
										path: routePaths.fileMonitoring.payrollConfirmation.fileLineDetails.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.payrollConfirmation.fileLineDetails.path, {
														fileId: data.params.fileId ?? '',
													})}
												>
													Payroll Confirmation File Details
												</Link>
											),
										},
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.payrollConfirmation.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.providerConfirmation.path}/*`,
							flag: transformers11348,
							handle: {
								crumb: () => (
									<Link href={routePaths.fileMonitoring.providerConfirmation.path}>Provider Confirmation</Link>
								),
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/provider-confirmation-summary/provider-confirmation-summary-page').then(
												(module) => ({
													Component: module.default,
												}),
											),
									},
									{
										lazy: () =>
											// TODO - colin fix once Nick lands detail page
											import('pages/provider-confirmation-summary/provider-confirmation-summary-page').then(
												(module) => ({
													Component: module.default,
												}),
											),
										path: routePaths.fileMonitoring.providerConfirmation.fileLineDetails.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.providerConfirmation.fileLineDetails.path, {
														fileId: data.params.fileId ?? '',
													})}
												>
													Provider Confirmation File Details
												</Link>
											),
										},
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.providerConfirmation.path}/`,
								},
							),
						},
						{
							path: routePaths.fileMonitoring.providerRemittance.path,
							lazy: () =>
								import('pages/provider-remittance-files-summary').then((module) => ({
									Component: module.default,
								})),
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.providerRemittance.path}>Provider Remittance</Link>,
							},
						},
					],
					{ stripLeading: `${routePaths.fileMonitoring.root}/` },
				),
			},
		],
		{},
	)

	return routes
}
