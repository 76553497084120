import { API_NAME } from 'api/benefit-elections/constants'
import { createRatesFormData, formHeaders } from 'components/form/util'
import { axiosInstance as axios } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'
import { Rates } from 'types/tenants/rates'
import { endpoints } from '../constants'
import { RatesStrategy } from '../types/rates-strategy'

const urlBuilder: UrlBuilder = new UrlBuilder(API_NAME)

export class RatesService {
	static async getRateById(benefitPlanId: string, rateInfoId: string): Promise<Rates> {
		const url: string = urlBuilder.setEndpoint(endpoints.BenefitPlans.getRates(benefitPlanId, rateInfoId)).url()

		return axios.get(url)
	}

	static async getMostRecentVersion(benefitPlanId: string): Promise<Rates> {
		const url: string = urlBuilder.setEndpoint(endpoints.BenefitPlans.getLatestRate(benefitPlanId)).url()

		return axios.get(url)
	}

	static editRate(id: string, data: any): Promise<Rates> {
		const url: string = urlBuilder.setEndpoint(endpoints.BenefitPlans.editRates(id, data.rateInfoId)).url()

		return axios.put(url, createRatesFormData(data), formHeaders)
	}

	static async getRatesStrategies(): Promise<RatesStrategy[]> {
		const url: string = urlBuilder.setEndpoint(endpoints.Rates.getRatesStrategies()).url()

		return axios.get(url)
	}
}
