import React from 'react'
import { Typography } from '@material-ui/core'

interface Props {
	align?: Align
	className?: string
	id?: string
	children: React.ReactNode
	color?: Color
	style?: Record<string, any>
	variant?: Variant
}

type Align = 'inherit' | 'left' | 'center' | 'right' | 'justify'
type Color = 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error' | undefined
type Variant =
	| 'button'
	| 'caption'
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'inherit'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'overline'
	| 'srOnly'
	| undefined

const Text: React.FC<Props> = ({ children, ...props }: Props) => {
	return <Typography {...props}>{children}</Typography>
}

Text.defaultProps = {
	color: 'textSecondary',
	variant: 'h5',
}
export default Text
