import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import MUIRadio, { RadioProps } from '@material-ui/core/Radio'
import MUIRadioGroup from '@material-ui/core/RadioGroup'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import React, { ReactElement } from 'react'

const useStyles = makeStyles({
	checkedIcon: {
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'&:before': {
			backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
			content: '""',
			display: 'block',
			height: 16,
			width: 16,
		},
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		borderRadius: '50%',
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		height: 16,
		'input:disabled ~ &': {
			background: 'rgba(206,217,224,.5)',
			boxShadow: 'none',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
		width: 16,
	},
	icon: {
		'$root.Mui-focusVisible &': { outline: '2px auto rgba(19,124,189,.6)', outlineOffset: 2 },
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		borderRadius: '50%',
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		height: 16,
		'input:disabled ~ &': { background: 'rgba(206,217,224,.5)', boxShadow: 'none' },
		'input:hover ~ &': { backgroundColor: '#ebf1f5' },
		width: 16,
	},
	infoIcon: {
		marginLeft: '0.25rem',
		verticalAlign: 'text-bottom',
	},
	root: { '&:hover': { backgroundColor: 'transparent' } },
})

const RadioButton: React.FC<RadioProps> = (props: RadioProps) => {
	const classes = useStyles()

	return (
		<MUIRadio
			className={classes.root}
			color='default'
			checkedIcon={<span className={classes.checkedIcon} />}
			icon={<span className={classes.icon} />}
			value={props.value}
			{...props}
			disableRipple
		/>
	)
}

interface RadioGroupProps {
	id?: string
	error?: string
	fieldLabel: string
	labelPlacement?: 'bottom' | 'end' | 'start' | 'top'
	name: string
	defaultValue?: string
	value: string | boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	children: JSX.Element[] | JSX.Element
	required?: boolean
	infoText?: string
}

export const RadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps) => {
	const classes = useStyles()

	return (
		<FormControl component='fieldset' error={Boolean(props.error)}>
			<FormLabel component='legend' required={props.required}>
				{props.fieldLabel}
				{props.infoText ? (
					<Tooltip title={props.infoText}>
						<InfoIcon className={classes.infoIcon} fontSize='small' />
					</Tooltip>
				) : null}
			</FormLabel>
			<MUIRadioGroup
				value={props.value}
				row
				onChange={props.onChange}
				defaultValue={props.defaultValue}
				name={props.name}
			>
				{props.children}
			</MUIRadioGroup>
		</FormControl>
	)
}

interface CSRadioProps {
	id?: string
	value: string | boolean
	label: string | ReactElement<any, any>
	disabled?: boolean
}

export const Radio: React.FC<CSRadioProps> = (props: CSRadioProps) => {
	return (
		<FormControlLabel
			value={props.value}
			labelPlacement='end'
			id={props.id}
			label={props.label}
			control={<RadioButton value={props.value} disabled={props?.disabled} />}
		/>
	)
}
