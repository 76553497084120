import { Link } from '@mui/material'
import { UIMatch, generatePath } from 'react-router-dom'
import { DynamicBreadcrumb } from 'routes/breadcrumbs'
import { routePaths } from 'routes/route-paths'
import { prepareRoutes } from 'routes/utils/prepareRoutes'

export const useOrgOpenEnrollmentRoutes = () => {
	return {
		path: routePaths.organizations.edit.openEnrollment.path,

		name: 'Open Enrollments',
		handle: {
			crumb: (data: UIMatch<any, any>) => (
				<Link
					href={generatePath(routePaths.organizations.edit.openEnrollment.path, {
						id: data.params.id ?? '',
					})}
				>
					Open Enrollments
				</Link>
			),
		},
		children: prepareRoutes(
			[
				{
					index: true,
					lazy: () =>
						import('pages/open-enrollment-windows').then((module) => ({
							Component: module.default,
						})),
				},
				{
					lazy: () => import('pages/edit-open-enrollment').then((module) => ({ Component: module.default })),
					path: routePaths.organizations.edit.openEnrollment.edit.path,
					handle: {
						crumb: (data) => <DynamicBreadcrumb portalId={data.params.oeWindowId ?? ''} />,
					},
				},
				{
					lazy: () => import('pages/create-open-enrollment').then((module) => ({ Component: module.default })),
					path: routePaths.organizations.edit.openEnrollment.create.path,
				},
			],
			{ stripLeading: `${routePaths.organizations.edit.openEnrollment.path}/` },
		),
	}
}
