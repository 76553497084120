import { PlanCoverage } from 'types/tenants/rates'
import * as yup from 'yup'

export interface RatesFormType {
	rateInfoId: string
	enrollmentEffectiveDate: Date | string
	notes: string
	planCoverages: PlanCoverage[]
	reference: File | string
}

export type RateFormTypeForPost = {
	enrollmentEffectiveDate: Date | string
	notes: string | null
	reference: File | string
	rateInfoId: string
	rates: FormPlanTiers[]
}

export const initialState: RatesFormType = {
	enrollmentEffectiveDate: '',
	notes: '',
	planCoverages: [],
	rateInfoId: '',
	reference: '',
}

export type FormPlanTiers = {
	planCoverageId: string
	planCoverageContent: string
	planTierId: string
	planRateId: string
	amount: number
	smoker: boolean
	ageFrom: number
	ageTo: number
}

// yup validation schema
export const validationSchema = yup.object().shape({
	enrollmentEffectiveDate: yup
		.date()
		.typeError('Enrollment Effective Date is required')
		.required('Enrollment Effective Date is required'),
})
