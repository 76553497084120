import { MutableRefObject, ReactNode, useEffect, useRef } from 'react'
import { useCrumbStore } from './state/crumbs'

export type BreadcrumbPortal = {
	portalId: string
	fallback?: ReactNode
}

export const DynamicBreadcrumb = ({ portalId, fallback }: BreadcrumbPortal) => {
	const ref = useRef<HTMLDivElement | null>(null)
	const updateCrumb = useCrumbStore((state) => state.updateCrumb)
	const removeCrumb = useCrumbStore((state) => state.removeCrumb)
	const fallbackId = `${portalId}-fallback`

	useEffect(() => {
		if (ref.current) {
			updateCrumb(portalId, ref as MutableRefObject<HTMLDivElement | null>)
		}

		return () => {
			removeCrumb(portalId)
		}
	}, [portalId, removeCrumb, updateCrumb])

	useEffect(() => {
		const observer = new MutationObserver(() => {
			const fallbackElement = document.getElementById(fallbackId)

			if (ref.current && ref.current.children.length > 1 && fallbackElement) {
				fallbackElement.remove()
			}
		})

		observer.observe(ref.current as Node, { childList: true })

		return () => {
			observer.disconnect()
		}
	}, [fallbackId])

	return (
		<div ref={ref} id={portalId}>
			<div id={fallbackId}>{fallback ? fallback : <DefaultFallback />}</div>
		</div>
	)
}

const DefaultFallback = () => {
	return <span>Loading...</span>
}
