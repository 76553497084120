import { Button as MuiButton } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Loading from 'components/loading'
import React from 'react'
import { StandardButtonProps } from './types'

const useStyles = makeStyles(() =>
	createStyles({
		buttonProgress: {
			left: 'calc(50% - 12px)',
			position: 'absolute',
			top: 'calc(50% - 12px)',
		},
		root: { alignItems: 'center', display: 'inline-block' },
		wrapper: { position: 'relative' },
	}),
)

/**
 * Material UI Button
 * @see https://material-ui.com/components/buttons/
 */
const Button: React.FC<StandardButtonProps> = (props: StandardButtonProps): React.ReactElement => {
	const loadingColor = props.color === 'primary' ? 'secondary' : 'primary'
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<MuiButton
					aria-label={props.ariaLabel}
					autoFocus={props.autoFocus}
					className={props.className ?? ''}
					color={props.color}
					disabled={props.isLoading || props.disabled}
					id={props.id ?? ''}
					onClick={props.onClick}
					style={props.style}
					variant={props.variant}
				>
					{props.children}
				</MuiButton>
				{props.isLoading && <Loading color={loadingColor} size={24} className={classes.buttonProgress} />}
			</div>
		</div>
	)
}

Button.defaultProps = {
	color: 'secondary',
	onClick: (e: any): any => e.target.value,
	size: 'medium',
	variant: 'contained',
}

export default Button
