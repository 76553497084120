import {
	DataGrid,
	GridCellParams,
	GridCellValue,
	GridColDef,
	GridColTypeDef,
	GridEditCellPropsParams,
	GridRowId,
} from '@material-ui/data-grid'
import { UseFormReturnType } from 'hooks/use-form'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RatesFormType } from './types'

interface RatesTableProps {
	data: any
	form: UseFormReturnType<RatesFormType>
}

type ArrangedColumn = {
	amount: number
	labelName: string
	planRateId: string
	planTierCode: string
	planTierId: string
}

const RatesTable: React.FC<RatesTableProps> = ({ data, form }: RatesTableProps) => {
	const [arrangedColumns, setArrangedColumns] = useState<ArrangedColumn[]>([])
	const [dataGridEditModel, setDataGridEditModel] = useState({})

	const handleEditCellChange = useCallback(
		({ id, field, props: { value }, props }: GridEditCellPropsParams) => {
			const updatedState = { [field]: { ...props, error: false } }
			const copiedPlanCoverages = form.values.planCoverages.map((pc) => {
				return {
					...pc,
				}
			})

			const tableRow = copiedPlanCoverages.find((c) => c.id === id)
			const planTierRow = tableRow?.planTierRates.find((ptr) => ptr.planTierCode === field)
			if (planTierRow) planTierRow.amount = value ? Number(value) / 100 : 0

			form.setFields({ target: { name: 'planCoverages', value: copiedPlanCoverages } })

			const newTableChangesState = {
				...dataGridEditModel[id],
				...updatedState,
			}

			setDataGridEditModel((state) => {
				return { ...state, [id]: { ...newTableChangesState } }
			})
		},
		[form, dataGridEditModel],
	)

	const columns: GridColDef[] = [
		{
			align: 'left',
			field: 'coverage',
			flex: 1,
			headerAlign: 'left',
			headerName: 'Coverage',
			type: 'string',
		},
		{
			align: 'left',
			field: 'smoker',
			flex: 1,
			headerAlign: 'left',
			headerName: 'Tobacco',
			// eslint-disable-next-line react/display-name
			renderCell: (params: GridCellParams) => {
				return (
					<>
						<p>{params.row.smoker ? 'Y' : 'N'}</p>
					</>
				)
			},
			type: 'boolean',
		},
		{
			align: 'left',
			field: 'ageFrom',
			flex: 1,
			headerAlign: 'left',
			headerName: 'Age From',
			type: 'string',
		},
		{
			align: 'left',
			field: 'ageTo',
			flex: 1,
			headerAlign: 'left',
			headerName: 'Age To',
			type: 'string',
		},
	]

	const currencyFormatter = new Intl.NumberFormat('en-US', {
		currency: 'USD',
		style: 'currency',
	})

	const usdPrice: GridColTypeDef = {
		flex: 1,
		type: 'number',
		valueFormatter: ({ value }) => currencyFormatter.format(Number(value) / 100),
	}

	useEffect(() => {
		const allEditableColumns = data[0].planTierRates.map((ptr) => ({
			...ptr,
		}))
		const employeeSpouse = allEditableColumns.find((ptr) => ptr.planTierCode === 'ES')
		const employeeChild = allEditableColumns.find((ptr) => ptr.planTierCode === 'EC')
		const employeeOnly = allEditableColumns.find((ptr) => ptr.planTierCode === 'EO')
		const spouseOnly = allEditableColumns.find((ptr) => ptr.planTierCode === 'SO')
		const childOnly = allEditableColumns.find((ptr) => ptr.planTierCode === 'CO')
		const family = allEditableColumns.find((ptr) => ptr.planTierCode === 'FA')

		if (employeeOnly) {
			employeeOnly['labelName'] = 'Employee Only'
			setArrangedColumns((prevState) => [...prevState, employeeOnly])
		}
		if (spouseOnly) {
			spouseOnly['labelName'] = 'Spouse Only'
			setArrangedColumns((prevState) => [...prevState, spouseOnly])
		}
		if (childOnly) {
			childOnly['labelName'] = 'Child Only'
			setArrangedColumns((prevState) => [...prevState, childOnly])
		}
		if (employeeSpouse) {
			employeeSpouse['labelName'] = 'Employee + Spouse'
			setArrangedColumns((prevState) => [...prevState, employeeSpouse])
		}
		if (employeeChild) {
			employeeChild['labelName'] = 'Employee + Children'
			setArrangedColumns((prevState) => [...prevState, employeeChild])
		}
		if (family) {
			family['labelName'] = 'Family'
			setArrangedColumns((prevState) => [...prevState, family])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	arrangedColumns.forEach((ptr) => {
		columns.push({
			editable: true,
			field: ptr.planTierCode,
			headerName: ptr.labelName,
			// eslint-disable-next-line react/display-name
			renderEditCell: (params: GridCellParams) => {
				const { value } = params
				const handleChange = (e) => {
					handleEditCellChange({
						field: ptr.planTierCode,
						id: params.id,
						props: { value: e.target.value as GridCellValue },
					})
				}

				const handleKeyDown = (e) => {
					if (e.key === '.') {
						e.preventDefault()
					}
				}

				return (
					<div style={{ display: 'flex' }}>
						<input
							style={{ border: '0', height: 'auto', marginLeft: '1.5rem', outline: 'none', width: '100%' }}
							type='number'
							onKeyDown={handleKeyDown}
							onChange={handleChange}
							value={String(value)}
							min={0}
							autoFocus
						/>
					</div>
				)
			},
			type: 'number',
			...usdPrice,
		})
	})

	const flattenPlanTierRates = (data) => {
		const flat = {}
		data.planTierRates.forEach((ptr) => {
			// Round to nearest integer to avoid precision issues where decimal could show as 1864.9999999998 instead of 1865
			flat[ptr.planTierCode] = Math.round(ptr.amount * 100)
		})

		return flat
	}

	const formattedData = useMemo(() => data.map((d) => ({ ...d, ...flattenPlanTierRates(d) })), [data])

	const handleCellClick = useCallback(
		(model: GridCellParams) => {
			if (model.isEditable) {
				const { field, id } = model
				const updatedModel = { [field]: { ...model, cellMode: 'edit', hasFocus: true } }
				const newTableChangesState = {
					...dataGridEditModel[id],
					...updatedModel,
				}

				setDataGridEditModel((state) => {
					return { ...state, [id]: { ...newTableChangesState } }
				})
			}
		},
		[dataGridEditModel],
	)

	const fields = arrangedColumns.map((column) => column.planTierCode)

	const getNextField = useCallback(
		(field: string, id: GridRowId, totalRowIds: number[]): [string, GridRowId] | [null, null] => {
			let nextId = id
			let nextField = field
			const index = fields.indexOf(field)

			if (index === -1) {
				return [null, null]
			}
			// last field in the row. Go to next row and start at beginning
			if (index === fields.length - 1) {
				nextField = fields[0]
				nextId = +id + 1
			} else {
				nextField = fields[index + 1]
			}

			// There are no more rows, return null
			if (Number(nextId) >= totalRowIds.length) {
				return [null, null]
			}

			return [nextField, nextId]
		},
		[fields],
	)

	const handleCellKeyDown = useCallback(
		(model: GridCellParams, e: React.KeyboardEvent<Element>) => {
			if (model.cellMode === 'edit' && e.key === 'Tab') {
				e.stopPropagation()
				e.preventDefault()
				const { api, field, id } = model

				const totalRows = api.getAllRowIds()

				const [nextField, nextRowId] = getNextField(field, id, totalRows)

				api.setCellMode(id, field, 'view')

				if (nextRowId !== null && nextField) {
					api.setCellMode(nextRowId, nextField, 'edit')
					api.setCellFocus(nextRowId, nextField)
				}
			}
		},
		[getNextField],
	)

	return (
		<div>
			<DataGrid
				rows={formattedData}
				columns={columns}
				editRowsModel={dataGridEditModel}
				onEditCellPropsChange={handleEditCellChange}
				onCellKeyDown={handleCellKeyDown}
				onCellClick={handleCellClick}
				pagination
				autoHeight
			/>
		</div>
	)
}

export default RatesTable
