import styled from 'styled-components'

const ResetButton = styled.button`
	background: transparent;
	border: none;
	color: inherit;
	font: inherit;
	line-height: normal;
	margin: 0;
	overflow: visible;
	padding: 0;
	text-align: left;
	vertical-align: middle;
	width: auto;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
`

export default ResetButton
